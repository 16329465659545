import React, { useContext } from 'react'
import { Droppable } from 'react-beautiful-dnd'

import PanelList from '../../PanelComponents/PanelList/PanelList'

import { PanelContext } from '../../../providers/panels/panel.provider'

import './Canvas.scss'

const Canvas = () => {
  const { renderedColumns } = useContext(PanelContext);
  const canvasColumn = renderedColumns["canvas"];

  return (
    <Droppable
      droppableId={canvasColumn.id}
    >
      {(provided, snapshot) => (
        <div
          className={"tb-canvas " + (snapshot.isDraggingOver ? "hovering" : "")}
          ref={provided.innerRef}
          {...provided.droppableProps}
        >
          <PanelList column={canvasColumn} />
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  )
}

export default Canvas
