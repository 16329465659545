import $ from 'jquery'

export const onRemovePanel = (panel, renderedColumns) => {
    const columns = renderedColumns;
    // ref the current canvas object in full
    const currentCanvas = columns.canvas;
    // create an array of the current items in the canvas column.
    const currentCanvasIds = Array.from(currentCanvas.panelIds);
    // create new array they removes the id that matches the remove button click panel id
    const newCanvasIds = currentCanvasIds.filter(id => id !== panel.id);

    const newCanvas = {
      ...currentCanvas,
      panelIds: newCanvasIds
    }

    // Great ok that handles removing, but now I have to put it back into its original spot

    // Similar to above, find current state from the original panels section
    // grab the panel id array
    // pop it back in there and set a new object to the state
    const currentOrigin = columns[panel.folder];
    const currentOriginIds = Array.from(currentOrigin.panelIds);
    currentOriginIds.splice(0, 0, panel.id);

    const newOrigin = {
      ...currentOrigin,
      panelIds: currentOriginIds
    }

    const newColumns = {
        ...columns,
        [panel.folder]: newOrigin,
        canvas: newCanvas
    }

    return newColumns;
}

// Drag End
export const parseDragEnd = (result, renderedColumns) => {
    const { destination, source, draggableId } = result;

    const columns = renderedColumns;
    

    // <!-- Edge Cases
    //  1. if drag item from canvas, need to refire JS because HTML gets reset
    if(source.droppableId === "canvas"){
        refireJS(draggableId);
    }

    // 2. If dropped outside any droppable, do nothing
    if (!destination) {
        return renderedColumns
    }

    // 3. If start drag but drops in same spot
    if (
        destination.droppableId === source.droppableId &&
        destination.index === source.index
    ) {
        return renderedColumns
    }
    // Edge Cases --!>
    
    // setting the start & finish here, because we need to return above if there is no destination
    const start = columns[source.droppableId];
    const finish = columns[destination.droppableId];

    // <!-- Drop into Same Column but in a new order
    if(start === finish) {
        
        // 1. Create new array, remove dragged item, and replace in new spot
        const newPanelIds = Array.from(start.panelIds); // new Array of start column ids
        newPanelIds.splice(source.index, 1); // Remove source item from new array
        newPanelIds.splice(destination.index, 0, draggableId); //insert at destination
        
        // 2. !! Keep Header & Footer at the top/bottom of canvas
        const headerItem = newPanelIds.filter((header) => header.startsWith('header')).toString();
        const headerIndex = newPanelIds.findIndex((header) => header.startsWith('header'));
        if (newPanelIds.includes(headerItem)) {
            newPanelIds.splice(headerIndex, 1);
            newPanelIds.unshift(headerItem);
        }

        const menuItem = newPanelIds.filter((menu) => menu.startsWith('mobile')).toString();
        const menuIndex = newPanelIds.findIndex((menu) => menu.startsWith('mobile'));

        if (newPanelIds.includes(menuItem)) {
            newPanelIds.splice(menuIndex, 1);
            newPanelIds.unshift(menuItem);
        }

        const footerItem = newPanelIds.filter((footer) => footer.startsWith('footer')).toString();
        const footerIndex = newPanelIds.findIndex((footer) => footer.startsWith('footer'));
        if (newPanelIds.includes(footerItem)) {
            newPanelIds.splice(footerIndex, 1);
            newPanelIds.push(footerItem);
        }

        // 3. Create new column object to be returned into full columns state
        const newColumn = {
            ...start,
            panelIds: newPanelIds,
        }

        // 4. Return new column object
        return {
            ...columns,
            [newColumn.id]: newColumn
        }
    }
    // Drop into Same Column -->
    

    // <!-- Dropping into a different column
    if(start !== finish) {
    
        // 1. New Start array & column object that removes the panel being dragged out
        const startPanelIds = Array.from(start.panelIds);
        startPanelIds.splice(source.index, 1); //remove one item from new array (delete this from nav one)

        const newStart = {
        ...start,
        panelIds: startPanelIds
        }

        // 2. New Finish array that adds the panel being dragged in
        const finishPanelIds = Array.from(finish.panelIds);
        finishPanelIds.splice(destination.index, 0, draggableId); //insert at destination

        // 3. Keep Header & Footer at the top/bottom
        const headerItem = finishPanelIds.filter((header) => header.startsWith('header')).toString();
        const headerIndex = finishPanelIds.findIndex((header) => header.startsWith('header'));
        if (finishPanelIds.includes(headerItem)) {
            finishPanelIds.splice(headerIndex, 1);
            finishPanelIds.unshift(headerItem);
        }

        const menuItem = finishPanelIds.filter((menu) => menu.startsWith('mobile')).toString();
        const menuIndex = finishPanelIds.findIndex((menu) => menu.startsWith('mobile'));

        if (finishPanelIds.includes(menuItem)) {
            finishPanelIds.splice(menuIndex, 1);
            finishPanelIds.unshift(menuItem);
        }

        const footerItem = finishPanelIds.filter((footer) => footer.startsWith('footer')).toString();
        const footerIndex = finishPanelIds.findIndex((footer) => footer.startsWith('footer'));
        if (finishPanelIds.includes(footerItem)) {
            finishPanelIds.splice(footerIndex, 1);
            finishPanelIds.push(footerItem);
        }

        // 4. Create new Finish column object (after header/footer check)
        const newFinish = {
            ...finish,
            panelIds: finishPanelIds
        }

        // 5. Return new column object with updated start & finish data
        return {
            ...columns,
            [newStart.id]: newStart,
            [newFinish.id]: newFinish
        }
    }
    // Dropping into a different column -->

    // return default columns state back in case something extra weird happens
    return renderedColumns
}
 
export const loadJS = (panel) => {
    
    $.post('/api/paneljs', panel, function (res) {

    }).done(function (res) {
        if (res !== "null") {
            if ($("head script[data-js-panel*=" + panel.id + "]").length) {
                $("head script[data-js-panel*=" + panel.id + "]").remove();
            }
            var script = document.createElement('script');
            script.setAttribute("data-js-panel", panel.id);
            script.type = "application/javascript";
            document.head.appendChild(script);
            script.appendChild(document.createTextNode(res));
        }
    }).done(function () {
        return true
    });
}

// Refire JS
export const refireJS = (draggableId) => {
    
    setTimeout(
        function () {
          var res = $("head script[data-js-panel*=" + draggableId + "]").text();
          var script = $("head script[data-js-panel*=" + draggableId + "]");
          script.empty();
          script.remove();
          //$('head').append($('script[data-js-panel*=' + draggableId));
          script = document.createElement('script');
          script.setAttribute('data-js-panel', draggableId);
          script.type = "application/javascript";
          document.head.appendChild(script);
          script.appendChild(document.createTextNode(res));
        }, 50
      )
}

export const postIncludes = (combinedProject) => {
    $.post('/api/includes', combinedProject, function (res) {
        postScss(res);
    });
}

export const postScss = (imports) => {
    $.post('/api/scss', {
        data: {data: imports}
    }, function (res) {
        var css = res;
        const buildcss = $('style#build-css');


        if (buildcss.length) {
            let style = document.getElementById('build-css');
            $(style).empty();
            style.appendChild(document.createTextNode(css));
        } else {
            let style = document.createElement('style');
            document.head.appendChild(style);
            $(style).attr('id', 'build-css');
            style.appendChild(document.createTextNode(css));
        }

        checkHeight();
    }); //customize with name of id for specific scss
}

export const checkHeight = () => {
    
    if ($(".tb-canvas .panel-wrap").length) {
        function setIntervalHeight(callback, delay, repetitions) {
            var x = 0;
            var intervalID = window.setInterval(function () {
                var canvasHeight = $(".tb-canvas").outerHeight(false) * 0.65 + 100;
                $(".tb-canvas-inner").height(canvasHeight);
               if (++x === repetitions) {
                   window.clearInterval(intervalID);
               }
            }, delay);
        }
        setIntervalHeight(function () {
            
        }, 500, 5);
    }
}