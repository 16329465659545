import React, { useContext } from 'react';
import { DragDropContext } from 'react-beautiful-dnd'

import Header from './components/TopBanner/Header/Header'
import CanvasContainer from './components/CanvasArea/CanvasContainer/CanvasContainer'
import SideBarTabs from './components/SideBar/SideBarTabs/SideBarTabs'
import ModalContainer from './components/Modals/ModalContainer/ModalContainer'

import { PanelContext } from './providers/panels/panel.provider'

import './App.scss';

function App() {
  
  const { handleDragEnd, handleModalClass } = useContext(PanelContext);

  const onDragEnd = result => {
    handleDragEnd(result);
  };


  // Created a function called hashHandler because when you use 
  // google's forgot password (i think forgot password, could be another google email),
  // and google sends you an email to reset and return to the theme builder, QA wanted
  // the signin modal to pop up instantly. 
  // this catches themebuilder.finalsite.com/#signup and will pop upen the signin modal

  const hashHandler = () => {
    if (window.location.hash === '#signin') {
      handleModalClass('modal-signin');
    }

    window.location.hash = '';
  }

  if(window.location.hash) {
    hashHandler();
  }
  
  window.addEventListener('hashchange', hashHandler, false);


  return (
    <>
      <Header />
      <div className="tb-body-wrapper">
        <DragDropContext
          onDragEnd={onDragEnd}
        >
          <SideBarTabs />
          <CanvasContainer />
        </DragDropContext>
      </div>
      <ModalContainer />
    </>
  );
}

export default App;
