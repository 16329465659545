import React, { useState } from 'react'

import SignIn from '../../SignUpAndSignIn/SignIn/SignIn'
import SignUp from '../../SignUpAndSignIn/SignUp/SignUp'
import ModalCloseButton from '../../ReusableComponents/ModalCloseButton/ModalCloseButton'
import './ModalSignInSignUp.scss'

const ModalSignInSignUp = ({handleModalClass}) => {
    const [signInView, setSignInView] = useState(true);
    const toggleView = () => {
        setSignInView(!signInView);
    }
    return (
        <div>
            <ModalCloseButton onClick={()=>handleModalClass('')}>Close</ModalCloseButton>
            {
                signInView ?
                <SignIn toggleView={toggleView} /> :
                <SignUp toggleView={toggleView} />
            }
            <a className="privacy-policy" rel="noopener noreferrer" target="_blank" href="https://www.finalsite.com/privacy-policy">Privacy Policy</a>
        </div>
    )
}

export default ModalSignInSignUp
