import React from 'react'
import FsLogo from '../../../assets/logo-updated.png'

import './Logo.scss'

const Logo = () => {
    return (
    <div>
        <div className="tb-logo-container">
            <div className="tb-logo-image">
                <h1><img src={FsLogo} alt="Finalsite Theme Builder" /></h1>
            </div>
            <div className="tb-logo-sub-title">Theme Builder</div>
        </div>
    </div>
)};


export default Logo
