import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

import PanelProvider from './providers/panels/panel.provider'
import UserProvider from './providers/user/user.provider'

//remove
import './index.css';


ReactDOM.render(
  <UserProvider>
    <PanelProvider>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </PanelProvider>
  </UserProvider>,
  document.getElementById('root')
);

