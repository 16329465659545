export default {
    // "header-1-v1": {
    //     id: "header-1-v1", folder: "headers", title: "Header 1-v1", elementId: 6206
    // },
    "header-1": {
        id: "header-1", folder: "headers", title: "Header 1", elementId: 7611
    },
    "header-2": {
        id: "header-2", folder: "headers", title: "Header 2", elementId: 7630
    },
    "header-3": {
        id: "header-3", folder: "headers", title: "Header 3", elementId: 7650
    },
    "header-4": {
        id: "header-4", folder: "headers", title: "Header 4", elementId: 7555
    },
    "header-5": {
        id: "header-5", folder: "headers", title: "Header 5", elementId: 7575
    },
    "header-6": {
        id: "header-6", folder: "headers", title: "Header 6", elementId: 7530
    },
    "header-7": {
        id: "header-7", folder: "headers", title: "Header 7", elementId: 7268
    },
    "header-8": {
        id: "header-8", folder: "headers", title: "Header 8", elementId: 63583
    },
    "header-9": {
        id: "header-9", folder: "headers", title: "Header 9", elementId: 7472
    },
    "header-10": {
        id: "header-10", folder: "headers", title: "Header 10", elementId: 7383
    },
    "header-11": {
        id: "header-11", folder: "headers", title: "Header 11", elementId: 7419
    },
    "header-12": {
        id: "header-12", folder: "headers", title: "Header 12", elementId: 7445
    },
    "header-13": {
        id: "header-13", folder: "headers", title: "Header 13", elementId: 42704
    },
    "header-14": {
        id: "header-14", folder: "headers", title: "Header 14", elementId: 42920
    },
    "header-15": {
        id: "header-15", folder: "headers", title: "Header 15", elementId: 42944
    },
    "header-16": {
        id: "header-16", folder: "headers", title: "Header 16", elementId: 42973
    },
    "footer-1": {
        id: "footer-1", folder: "footers", title: "Footer 1", elementId: 9586
    },
    "footer-2": {
        id: "footer-2", folder: "footers", title: "Footer 2", elementId: 9629
    },
    "footer-3": {
        id: "footer-3", folder: "footers", title: "Footer 3", elementId: 9647
    },
    "footer-4": {
        id: "footer-4", folder: "footers", title: "Footer 4", elementId: 9665
    },
    "footer-5": {
        id: "footer-5", folder: "footers", title: "Footer 5", elementId: 9683
    },
    "footer-6": {
        id: "footer-6", folder: "footers", title: "Footer 6", elementId: 9701
    },
    "footer-7": {
        id: "footer-7", folder: "footers", title: "Footer 7", elementId: 9720
    },
    "footer-8": {
        id: "footer-8", folder: "footers", title: "Footer 8", elementId: 9742
    },
    "footer-9": {
        id: "footer-9", folder: "footers", title: "Footer 9", elementId: 9762
    },
    "footer-10": {
        id: "footer-10", folder: "footers", title: "Footer 10", elementId: 9604
    },
    "footer-11": {
        id: "footer-11", folder: "footers", title: "Footer 11", elementId: 9782
    },
    "footer-12": {
        id: "footer-12", folder: "footers", title: "Footer 12", elementId: 9801
    },
    "footer-13": {
        id: "footer-13", folder: "footers", title: "Footer 13", elementId: 43157
    },
    "slideshow-1": {
        id: "slideshow-1", folder: "slideshows", title: "Slideshow 1", elementId: 9835
    },
    "slideshow-2": {
        id: "slideshow-2", folder: "slideshows", title: "Slideshow 2", elementId: 6354
    },
    "slideshow-3": {
        id: "slideshow-3", folder: "slideshows", title: "Slideshow 3", elementId: 9837
    },
    "slideshow-4": {
        id: "slideshow-4", folder: "slideshows", title: "Slideshow 4", elementId: 9839
    },
    "slideshow-5": {
        id: "slideshow-5", folder: "slideshows", title: "Slideshow 5", elementId: 9577
    },
    "slideshow-6": {
        id: "slideshow-6", folder: "slideshows", title: "Slideshow 6", elementId: 9841
    },
    "slideshow-7": {
        id: "slideshow-7", folder: "slideshows", title: "Slideshow 7", elementId: 9843
    },
    "slideshow-8": {
        id: "slideshow-8", folder: "slideshows", title: "Slideshow 8", elementId: 9845
    },
    "slideshow-9": {
        id: "slideshow-9", folder: "slideshows", title: "Slideshow 9", elementId: 9851
    },
    "slideshow-10": {
        id: "slideshow-10", folder: "slideshows", title: "Slideshow 10", elementId: 9847
    },
    "slideshow-11-v2": {
        id: "slideshow-11-v2", folder: "slideshows", title: "Slideshow 11", elementId: 43177
    },
    "slideshow-12-v2": {
        id: "slideshow-12-v2", folder: "slideshows", title: "Slideshow 12", elementId: 43197
    },
    "slideshow-13-v2": {
        id: "slideshow-13-v2", folder: "slideshows", title: "Slideshow 13", elementId: 43225
    },
    "slideshow-14-v2": {
        id: "slideshow-14-v2", folder: "slideshows", title: "Slideshow 14", elementId: 43620
    },
    "slideshow-13-v2": {
        id: "slideshow-13-v2", folder: "slideshows", title: "Slideshow 13", elementId: 43225
    },
    "mission-statement-1": {
        id: "mission-statement-1", folder: "mission-statement", title: "Mission 1", elementId: 37578
    },
    "mission-statement-2": {
        id: "mission-statement-2", folder: "mission-statement", title: "Mission 2", elementId: 37581
    },
    "mission-statement-3": {
        id: "mission-statement-3", folder: "mission-statement", title: "Mission 3", elementId: 37584
    },
    "mission-statement-4": {
        id: "mission-statement-4", folder: "mission-statement", title: "Mission 4", elementId: 37587
    },
    "mission-statement-5": {
        id: "mission-statement-5", folder: "mission-statement", title: "Mission 5", elementId: 37590
    },
    "mission-statement-6": {
        id: "mission-statement-6", folder: "mission-statement", title: "Mission 6", elementId: 43858
    },
    "post-panel-1": {
        id: "post-panel-1", folder: "post-panels", title: "News 1", elementId: 6042
    },
    "post-panel-2": {
        id: "post-panel-2", folder: "post-panels", title: "News 2", elementId: 6067
    },
    "post-panel-3": {
        id: "post-panel-3", folder: "post-panels", title: "News 3", elementId: 6069
    },
    "post-panel-4": {
        id: "post-panel-4", folder: "post-panels", title: "News 4", elementId: 6072
    },
    "post-panel-5": {
        id: "post-panel-5", folder: "post-panels", title: "News 5", elementId: 6087
    },
    "post-panel-6": {
        id: "post-panel-6", folder: "post-panels", title: "News 6", elementId: 14681
    },
    "post-panel-7": {
        id: "post-panel-7", folder: "post-panels", title: "News 7", elementId: 16188
    },
    "post-panel-8": {
        id: "post-panel-8", folder: "post-panels", title: "News 8", elementId: 16003
    },
    "post-panel-9": {
        id: "post-panel-9", folder: "post-panels", title: "News 9", elementId: 16181
    },
    "post-panel-10": {
        id: "post-panel-10", folder: "post-panels", title: "News 10", elementId: 15461
    },
    "post-panel-11": {
        id: "post-panel-11", folder: "post-panels", title: "News 11", elementId: 16372
    },
    "post-panel-12": {
        id: "post-panel-12", folder: "post-panels", title: "News 12", elementId: 15465
    },
    "post-panel-13": {
        id: "post-panel-13", folder: "post-panels", title: "News 13", elementId: 43894
    },
    "calendar-1": {
        id: "calendar-1", folder: "calendars", title: "Calendar 1", elementId: 6112
    },
    "calendar-2": {
        id: "calendar-2", folder: "calendars", title: "Calendar 2", elementId: 6140
    },
    "calendar-3": {
        id: "calendar-3", folder: "calendars", title: "Calendar 3", elementId: 6142
    },
    "calendar-4": {
        id: "calendar-4", folder: "calendars", title: "Calendar 4", elementId: 6156
    },
    "calendar-5": {
        id: "calendar-5", folder: "calendars", title: "Calendar 5", elementId: 6110
    },
    "calendar-6": {
        id: "calendar-6", folder: "calendars", title: "Calendar 6", elementId: 17974
    },
    "calendar-7": {
        id: "calendar-7", folder: "calendars", title: "Calendar 7", elementId: 18010
    },
    "calendar-8": {
        id: "calendar-8", folder: "calendars", title: "Calendar 8", elementId: 18025
    },
    "calendar-9": {
        id: "calendar-9", folder: "calendars", title: "Calendar 9", elementId: 17989
    },
    "calendar-10": {
        id: "calendar-10", folder: "calendars", title: "Calendar 10", elementId: 18436
    },
    "calendar-11": {
        id: "calendar-11", folder: "calendars", title: "Calendar 11", elementId: 18845
    },
    "post-calendar-1": {
        id: "post-calendar-1", folder: "post-calendar-panels", title: "News + Calendar 1", elementId: 20102
    },
    "post-calendar-2": {
        id: "post-calendar-2", folder: "post-calendar-panels", title: "News + Calendar 2", elementId: 20105
    },
    "post-calendar-3": {
        id: "post-calendar-3", folder: "post-calendar-panels", title: "News + Calendar 3", elementId: 20109
    },
    "post-calendar-4": {
        id: "post-calendar-4", folder: "post-calendar-panels", title: "News + Calendar 4", elementId: 20557
    },
    "post-calendar-5": {
        id: "post-calendar-5", folder: "post-calendar-panels", title: "News + Calendar 5", elementId: 20562
    },
    "post-calendar-6": {
        id: "post-calendar-6", folder: "post-calendar-panels", title: "News + Calendar 6", elementId: 20576
    },
    "post-calendar-7": {
        id: "post-calendar-7", folder: "post-calendar-panels", title: "News + Calendar 7", elementId: 20581
    },
    "post-calendar-8": {
        id: "post-calendar-8", folder: "post-calendar-panels", title: "News + Calendar 8", elementId: 20585
    },
    "post-calendar-9": {
        id: "post-calendar-9", folder: "post-calendar-panels", title: "News + Calendar 9", elementId: 20591
    },
    "post-calendar-10": {
        id: "post-calendar-10", folder: "post-calendar-panels", title: "News + Calendar 10", elementId: 20598
    },
    "post-calendar-11": {
        id: "post-calendar-11", folder: "post-calendar-panels", title: "News + Calendar 11", elementId: 21246
    },
    "post-calendar-12": {
        id: "post-calendar-12", folder: "post-calendar-panels", title: "News + Calendar 12", elementId: 43913
    },
    "post-calendar-13": {
        id: "post-calendar-13", folder: "post-calendar-panels", title: "News + Calendar 13", elementId: 43920
    },
    "testimonial-1": {
        id: "testimonial-1", folder: "testimonials", title: "Testimonials 1", elementId: 6178
    },
    "testimonial-2": {
        id: "testimonial-2", folder: "testimonials", title: "Testimonials 2", elementId: 6202
    },
    "testimonial-3": {
        id: "testimonial-3", folder: "testimonials", title: "Testimonials 3", elementId: 6228
    },
    "testimonial-4": {
        id: "testimonial-4", folder: "testimonials", title: "Testimonials 4", elementId: 6244
    },
    "testimonial-5": {
        id: "testimonial-5", folder: "testimonials", title: "Testimonials 5", elementId: 6266
    },
    "infographic-1": {
        id: "infographic-1", folder: "infographics", title: "Infographic 1", elementId: 5968
    },
    "infographic-2": {
        id: "infographic-2", folder: "infographics", title: "Infographic 2", elementId: 5947
    },
    "infographic-3": {
        id: "infographic-3", folder: "infographics", title: "Infographic 3", elementId: 5888
    },
    "infographic-4": {
        id: "infographic-4", folder: "infographics", title: "Infographic 4", elementId: 5996
    },
    "infographic-5": {
        id: "infographic-5", folder: "infographics", title: "Infographic 5", elementId: 39807
    },
    "image-marquee-1": {
        id: "image-marquee-1", folder: "image-marquees", title: "Image Marquee 1", elementId: 26519
    },
    "image-marquee-2": {
        id: "image-marquee-2", folder: "image-marquees", title: "Image Marquee 2", elementId: 26483
    },
    "image-marquee-3": {
        id: "image-marquee-3", folder: "image-marquees", title: "Image Marquee 3", elementId: 26507
    },
    "image-marquee-4": {
        id: "image-marquee-4", folder: "image-marquees", title: "Image Marquee 4", elementId: 26495
    },
    "showcase-1": {
        id: "showcase-1", folder: "showcases", title: "Showcase 1", elementId: 5781
    },
    "showcase-2": {
        id: "showcase-2", folder: "showcases", title: "Showcase 2", elementId: 5804
    },
    "showcase-3": {
        id: "showcase-3", folder: "showcases", title: "Showcase 3", elementId: 6044
    },
    "showcase-4": {
        id: "showcase-4", folder: "showcases", title: "Showcase 4", elementId: 33984
    },
    "showcase-5": {
        id: "showcase-5", folder: "showcases", title: "Showcase 5", elementId: 35920
    },
    "showcase-6": {
        id: "showcase-6", folder: "showcases", title: "Showcase 6", elementId: 43937
    },
    "showcase-7": {
        id: "showcase-7", folder: "showcases", title: "Showcase 7", elementId: 45460
    },
    "icon-panel-1": {
        id: "icon-panel-1", folder: "icon-panels", title: "Icon Panel 1", elementId: 5832
    },
    "icon-panel-2": {
        id: "icon-panel-2", folder: "icon-panels", title: "Icon Panel 2", elementId: 5834
    },
    "icon-panel-3": {
        id: "icon-panel-3", folder: "icon-panels", title: "Icon Panel 3", elementId: 5836
    },
    "icon-panel-4": {
        id: "icon-panel-4", folder: "icon-panels", title: "Icon Panel 4", elementId: 10199
    },
    "icon-panel-5": {
        id: "icon-panel-5", folder: "icon-panels", title: "Icon Panel 5", elementId: 10203
    },
    "icon-panel-6": {
        id: "icon-panel-6", folder: "icon-panels", title: "Icon Panel 6", elementId: 10206
    },
    "icon-panel-7": {
        id: "icon-panel-7", folder: "icon-panels", title: "Icon Panel 7", elementId: 10218
    },
    "icon-panel-8": {
        id: "icon-panel-8", folder: "icon-panels", title: "Icon Panel 8", elementId: 10254
    },
    "icon-panel-9": {
        id: "icon-panel-9", folder: "icon-panels", title: "Icon Panel 9", elementId: 10259
    },
    "icon-panel-10": {
        id: "icon-panel-10", folder: "icon-panels", title: "Icon Panel 10", elementId: 43645
    },
    "button-panel-1": {
        id: "button-panel-1", folder: "button-panels", title: "Button Panel 1", elementId: 6342
    },
    "button-panel-2": {
        id: "button-panel-2", folder: "button-panels", title: "Button Panel 2", elementId: 6318
    },
    "button-panel-3": {
        id: "button-panel-3", folder: "button-panels", title: "Button Panel 3", elementId: 37728
    },
    "button-panel-4": {
        id: "button-panel-4", folder: "button-panels", title: "Button Panel 4", elementId: 6295
    },
    "button-panel-5": {
        id: "button-panel-5", folder: "button-panels", title: "Button Panel 5", elementId: 6291
    },
    "button-panel-6": {
        id: "button-panel-6", folder: "button-panels", title: "Button Panel 6", elementId: 39702
    },
    "button-panel-7": {
        id: "button-panel-7", folder: "button-panels", title: "Button Panel 7", elementId: 43686
    },
    "social-panel-1": {
        id: "social-panel-1", folder: "social-panels", title: "Social Media 1", elementId: 6402
    },
    "social-panel-2": {
        id: "social-panel-2", folder: "social-panels", title: "Social Media 2", elementId: 6404
    },
    "social-panel-3": {
        id: "social-panel-3", folder: "social-panels", title: "Social Media 3", elementId: 43877
    },
    
    "styleguide": {
        id: "styleguide", folder: "styleguides", title: "Style Guide", elementId: 870
    },
    "mobilemenu": {
        id: "mobilemenu", folder: "mobilemenus", title: "Mobile Menu", elementId: 9248
    },
    // "mobilemenu-2": {
    //     id: "mobilemenu-2", folder: "mobilemenus", title: "Mobile Menu 2", elementId: 43019
    // },
    "1-column-resource": {
        id: "1-column-resource", folder: "multi-content-panels", title: "1 Column Resource", elementId: 9349
    },
    "1-column-content-panel": {
        id: "1-column-content-panel", folder: "multi-content-panels", title: "1 Column Content Panel", elementId: 9354
    },
    "2-column-posts-calendar": {
        id: "2-column-posts-calendar", folder: "multi-content-panels", title: "2 Column Posts + Calendar", elementId: 9357
    },
    "2-column-content": {
        id: "2-column-content", folder: "multi-content-panels", title: "2 Column Content Panel", elementId: 9363
    },
    "2-column-twitter-facebook": {
        id: "2-column-twitter-facebook", folder: "multi-content-panels", title: "2 Column Twitter + Facebook", elementId: 9369
    },
    "3-column-posts-calendar-twitter": {
        id: "3-column-posts-calendar-twitter", folder: "multi-content-panels", title: "3 Column Posts + Calendar + Twitter", elementId: 9375
    },
    "3-column-content": {
        id: "3-column-content", folder: "multi-content-panels", title: "3 Column Content", elementId: 9383
    },
    "3-column-twitter-facebook-buttons": {
        id: "3-column-twitter-facebook-buttons", folder: "multi-content-panels", title: "3 Column Twitter + FB + Buttons", elementId: 9391
    },
    "4-column-facebook-posts-calendar-buttons": {
        id: "4-column-facebook-posts-calendar-buttons", folder: "multi-content-panels", title: "4 Column FB + Posts + Calendar + Buttons", elementId: 9399
    }
}