import React, { useContext } from 'react'
import $ from 'jquery'

import CustomButton from '../../ReusableComponents/CustomButton/CustomButton'

import { PanelContext } from '../../../providers/panels/panel.provider'

import './CanvasTabs.scss'

const CanvasTabs = () => {

    const { handleCanvasView, renderedColumns, panelData, renderedBranding, handleBrandingToCanvas } = useContext(PanelContext);
    const canvasColumn = renderedColumns["canvas"];


    const handleToggleSiblingsActive = (evt, view) => {

        // <!--- toggle siblings active class
        evt = evt || window.event
        let targetButton = evt.target || evt.srcElement;
        let navElements = [...targetButton.parentElement.children];
        
        // # remove active then set active
        navElements.forEach(el=>{
            el.classList.remove("active");
        });
        targetButton.classList.add("active");
        // --!>

        // <!--- Pass the name of the button to the provider
        handleCanvasView(view);

        const mobileholder = $('iframe.mobile-view');
        const mobileholderParentClasses = mobileholder.parents('body')[0].classList;
        
        mobileholder.contents().find('body').empty();

        mobileholder.contents().find('body')[0].className = `${mobileholderParentClasses}`;


        // if (mobileholderParentClasses.contains('district-active')) {
        //     mobileholder.contents().find('body')[0].classList.add('district-active');
        // } else {
        //     mobileholder.contents().find('body')[0].classList.remove('district-active');
        // }

        if(view === "mobileview"){
            const homepagePanels = canvasColumn.panelIds.map(panelId => panelData[panelId]);
            // const HeaderLogoContainer = $('.tb-canvas #fsHeader .logo-container').html();
            const HeaderLogoContainer = $('.tb-canvas #fsHeader').html();
            

            mobileholder.contents().find('.panel-wrap[data-rbd-draggable-id*="styleguide"]').remove();


            // I have to push branding shit to this canvas too after header is done


            // Find head tags to transfer to iframe
            let body = mobileholder.contents();
            
            let head = mobileholder.contents().find("head");
            head.find('style#iframe-css').remove();
            $('head style#build-css').clone().attr('id', 'iframe-css').appendTo(head);

            homepagePanels.forEach(function (panel) {
                if (panel.folder !== "styleguides") {

                    var child = $('<div class="panelmobile"></div>');
                    mobileholder.contents().find('body').addClass('home').append(child);
                    
                    fetch('/api/panel/' + panel.id)
                    .then(response => response.text())
                    .then(function (html) {
                        // parse to elements so I can change where it starts instead of whole dom starting at <html>
                        var parser = new DOMParser();
                        var doc = parser.parseFromString(html, "text/html");
                        var element;

                        // setting the starting point to the proper element
                        // if styleguide, grab fsPageBodyWrapper instead
                        if (panel.folder === "styleguides") {
                            element = doc.getElementById('fsPageBodyWrapper');
                        } else {
                            element = doc.getElementById('fsEl_' + panel.elementId);
                        }


                        // Create #fsHeader and .fsHeader divs (same for footers)
                        if (panel.folder === "headers") {
                            $(element).wrap('<div id="fsHeader" class="fsHeader"><div class="fsBanner"></div></div>');
                            element = doc.getElementById('fsHeader');
                            
                        }
                        if (panel.folder === "footers") {
                            $(element).wrap('<div id="fsFooter" class="fsFooter"><div class="fsBanner"></div></div>');
                            element = doc.getElementById('fsFooter');
                        }
                        if (panel.folder === "mobilemenus") {
                            $(element).wrap('<div id="fsMenu"><div class="fsMenu"></div></div>');
                            element = doc.getElementById('fsMenu');
                            
                        }


                        //run function that moves the data image size to the src
                        var images = doc.querySelectorAll('[data-image-sizes]');
                        $(images).each(function () {
                            var _ = $(this);
                            var sizeData = JSON.parse(unescape(_.attr('data-image-sizes')));
                            _.attr('src', sizeData[sizeData.length - 1].url);
                        })

                        // take elements and convert back to text string so react can render as HTML (seems backwards but needed)
                        const elementToString = element.outerHTML;


                        child.append(elementToString);

                        handleBrandingToCanvas(renderedBranding);

                    }).then(function () {
                        // body.find('#fsHeader .logo-container').html(HeaderLogoContainer);
                        body.find('#fsHeader').html(HeaderLogoContainer);
                        
                        var _ = $('head script[data-js-panel="' + panel.id + '"]');
                        var code = _.html();
                        var jspanel = _.attr('data-js-panel');

                        head.find('script[data-js-panel*="' + jspanel + '"]').remove();

                        var script = document.createElement('script');
                        script.setAttribute('data-js-panel', jspanel);
                        script.type = "application/javascript";
                        head.append(script);
                        script.appendChild(document.createTextNode(code));

                    });
                }
            });

            //cleanup
            mobileholder.contents().find('.remove-panel, .preview-image').remove();

            //no action on links clicked
            const code = "$('.panelmobile').click('a', function(e){e.preventDefault();});"
            head.find('script[data-js-panel*="no-links"]').remove();
            head.append($('<script data-js-panel="no-links">').html(code));
        }

    }

    return (
        <nav className={(canvasColumn.panelIds.length === 2 ? 'tb-canvas-tabs' : 'tb-canvas-tabs all-shown')} >
            <CustomButton onClick={()=>handleToggleSiblingsActive(this, 'homepage')} label="HOMEPAGE" className="active" />
            <CustomButton label="STYLE GUIDE" onClick={()=>handleToggleSiblingsActive(this, 'styleguide')} />
            <CustomButton label="MOBILE VIEW" className={(canvasColumn.panelIds.length === 2 ? 'inactive' : ' ')} onClick={()=>handleToggleSiblingsActive(this, 'mobileview')}/>
        </nav>
    )
}

export default CanvasTabs
