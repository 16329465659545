import React, { createContext, useState, useEffect } from 'react';

import { auth, createUserProfileDocument } from '../../firebase/firebase.utils'


export const UserContext = createContext({
    currentUser: {}
});

const UserProvider = ({ children }) => {
    
    // was trying to create an admin user to show extra functionality to me when logged in
    // I don't think I got anywhere, all instances of adminUser can probably be deleted
    // specifically was trying to add 'fetch html' buttons to each panel to easily 
    // be able to fetch panel html to update them when changes were made to them on composer
    const adminUser = `"jeff.snow@finalsite.com"`;


    
    const [currentUser, setCurrentUser] = useState({})
    let unsubscribeFromAuth = null;

    // console.log(currentUser);

    useEffect(() => {
        
        unsubscribeFromAuth = auth.onAuthStateChanged(async userAuth => {
            if (userAuth) {
                const userRef = await createUserProfileDocument(userAuth);
                userRef.onSnapshot(snapShot => {
                    setCurrentUser({
                        currentUser: {
                            id: snapShot.id,
                            ...snapShot.data()
                        }
                    });
                    //user auth response, nothing to do with database.
                    //console.log('exists', snapShot.data());
                });
            } else {
                //console.log('if userauth bad else, null i believe', userAuth);
                setCurrentUser({ currentUser: userAuth });
            }
        });
        return () => {
            unsubscribeFromAuth();
        }
    }, []);


    return (
        <UserContext.Provider
          value={{
            currentUser,
            adminUser
          }}
        >
          {children}
        </UserContext.Provider>
    );
}

export default UserProvider;