import React, { useContext } from 'react'

import Canvas from '../Canvas/Canvas'

import { PanelContext } from '../../../providers/panels/panel.provider'

import './CanvasContainer.scss'

const CanvasContainer = () => {

    const { canvasView } = useContext(PanelContext);

    return (
        <div className="tb-canvas-container fsHasRightBanner" id={canvasView}>
            <div className="tb-canvas-inner">
                <Canvas />
                <div className="mobile-iframe-container">
                    <iframe title="Mobile View" src="mobileiframe.html" id="mobileiframe" frameBorder="0" scrolling="yes" className="mobile-view hidden"></iframe>
                </div>
            </div>
        </div>
    )
}

export default CanvasContainer
