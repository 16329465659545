import React, { useContext, useEffect } from 'react'

import ToggleCheckbox from '../../ReusableComponents/ToggleCheckbox/ToggleCheckbox'

import { PanelContext } from '../../../providers/panels/panel.provider'

import './AccreditationsSection.scss'
import { UPDATE_BRANDING } from '../../../providers/panels/panel.types'

import $ from 'jquery'


const AccreditationsSection = () => {

    const { renderedBranding, dispatch } = useContext(PanelContext);

    const handleAccreditationsDisplay = (e) => {
        const newBranding = {
            ...renderedBranding,
            accreditations: e.target.checked
        }
        dispatch({type: UPDATE_BRANDING, payload: newBranding});
        if (renderedBranding.accreditations) {
            $('body').addClass('accreditations-active');
            // document.body.classList.add('accreditations-active')
        } else {
            $('body').removeClass('accreditations-active');
            // document.body.classList.remove('accreditations-active')
        }
    }
    

    useEffect(() => {

        if (renderedBranding.accreditations) {
            $('body').addClass('accreditations-active');
            if ($('.nav-accreditations').length) {
                const target = $('.nav-accreditations').offset().top;

                $('html,body').animate({
                    scrollTop: target
                }, 300);
            }
        } else {
            $('body').removeClass('accreditations-active');
        }
    }, [renderedBranding.accreditations]);

    useEffect(() => {
        if (renderedBranding.accreditations) {
            $('body').addClass('accreditations-active');
            // document.body.classList.add('accreditations-active')
        } else {
            $('body').removeClass('accreditations-active');
            // document.body.classList.remove('accreditations-active')
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className="tb-theme-accreditations">
            <div className="livedemo-description">Toggle to enable the footer accreditations</div>
            <div className="theme-checkbox-container">
                <h4>Accreditations</h4>
                <ToggleCheckbox
                    linkedTo="accreditationsCheckbox"
                    defaultChecked={renderedBranding.accreditations}
                    onChange={handleAccreditationsDisplay}
                />
            </div>
        </div>
    )
}

export default AccreditationsSection
