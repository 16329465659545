import React, { useContext } from 'react'

import CustomButton from '../../../ReusableComponents/CustomButton/CustomButton'
import CheckMark from '../../../ReusableComponents/CheckMark/CheckMark'

import { PanelContext } from '../../../../providers/panels/panel.provider'

import './ProjectItem.scss'

const ProjectItem = ({name, handleDeleteProject, toggleRenameView, handleLoadProject, setProjectToRename}) => {

    const { currentProject, userProjects } = useContext(PanelContext);

    
    
    return (
        <div className="project-item-container">
            <button className={`modal-project-item ${(name === currentProject) ? 'modal-project-current': ''}`} onClick={()=>handleLoadProject(name)}>
                {
                    (name === currentProject) ?
                    <CheckMark />
                    : null
                }
                {name}
            </button>
            <CustomButton
                className="tb-modal-button rename-project"
                onClick={()=>{
                    toggleRenameView(true);
                    setProjectToRename(name);
                }}
            >
                Rename
            </CustomButton>
            {   Object.keys(userProjects).length >= 2
                ? <CustomButton className="tb-modal-button delete-project" onClick={()=>handleDeleteProject(name)}>Delete</CustomButton>
                : null
            }
        </div>
    )
}

export default ProjectItem
