import { addProjectsToUser, getProjectsFromDB } from '../../firebase/firebase.utils'

export const initFirstProject = () => {
    return false
}



export const pushProjectsToDB = (projectObject, currentProject, userProjects) => {
    //const userProjectCurrent = userProjects[currentProject];
    //if(currentProject !== '' && JSON.stringify(userProjectCurrent) !== JSON.stringify(projectObject)){

    if(currentProject !== ''){
        const newUserProjects = {
            ...userProjects,
            [currentProject]: projectObject,
            currentProject: currentProject
        }
        addProjectsToUser(newUserProjects);
        //console.log('Actually adding Projects to DB', newUserProjects);

        //the reason i remove is because this returns back to state,
        //which shouldnt hold current project (string) in the projects object 
        //or it will show up in the project list
        const newUserProjectsSansCurrentProject = {
            ...userProjects,
            [currentProject]: projectObject
        }
        return newUserProjectsSansCurrentProject;
    }
    return userProjects;
}

export const loadProjects = async () => {
    let loadedProjects = await getProjectsFromDB();
    // console.log(loadedProjects);
    return loadedProjects;
}

export const parseLoadedData = (panels, data) => {
    const newData = Object.assign({}, data);
    const columns = newData;
    const canvas = newData.canvas;
    
    
    
    // create an array of the current items in the canvas column.
    const currentCanvasIds = Array.from(canvas.panelIds);
    
    currentCanvasIds.map((id) => {
        
        if(panels[id].folder !== "styleguides" && panels[id].folder !== "mobilemenus") {
            console.log(panels)
            const column = panels[id].folder;
            //const column = columns[folder];
            const newColIds = columns[column].panelIds.filter(e => e !== id);
            columns[column] = {
                ...columns[column],
                panelIds: newColIds
            }
        }
    })
    
    // create new array they removes the id that matches the remove button click panel id
    // console.log('newData', newData);

    return newData
}