import React, { useState, useContext } from 'react'

import FormInput from '../../ReusableComponents/FormInput/FormInput'
import CustomButton from '../../ReusableComponents/CustomButton/CustomButton'

import { PanelContext } from '../../../providers/panels/panel.provider'

import { auth, signInWithGoogle } from '../../../firebase/firebase.utils'

import './SignIn.scss'

const SignIn = ({toggleView}) => {

    const [credentials, setCredentials] = useState({email: '', password: ''});
    const [error, setError] = useState(null);
    const { handleModalClass } = useContext(PanelContext);

    const handleSubmit = async event => {
        event.preventDefault();
    
        const { email, password } = credentials;
    
        try {
            await auth.signInWithEmailAndPassword(email, password);
            setCredentials({ email: '', password: '' });
        } catch (error) {
            console.log(error);
            setError("Sorry, that email or password is not in our system.");
        }
    };

    const handleChange = event => {
        const { value, name } = event.target;
    
        setCredentials({
            ...credentials,
            [name]: value 
        });
    };

    return (
      <div className='tb-sign-in'>
        <div className="modal-title">Sign In</div>
        <span>Sign in with your email and password</span>

        <form onSubmit={handleSubmit}>
          <FormInput
            name='email'
            type='email'
            handleChange={handleChange}
            value={credentials.email}
            label='email'
            required
          />
          <FormInput
            name='password'
            type='password'
            value={credentials.password}
            handleChange={handleChange}
            label='password'
            required
          />
          {error !== null && (
              <div className="sign-in-error">
              {error}
              </div>
          )}
          <div className="sign-in-forgot-password"><button type="button" onClick={()=>handleModalClass('modal-forgotpassword')}>forgot password?</button></div>
          <div className='tb-sign-in-buttons'>
            <CustomButton className="tb-modal-button" type='submit'> Sign in </CustomButton>
            <CustomButton type="button" className="tb-modal-button with-google" onClick={signInWithGoogle}>
              Sign in with Google
            </CustomButton>
          </div>
        </form>
        <div className="tb-new-user"><span>New To Theme Builder?</span></div>
        <button className="tb-modal-button create-account" onClick={toggleView}>Create an Account</button>
      </div>
    )
}

export default SignIn
