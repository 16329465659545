import $ from 'jquery'

export const pushBrandingToCanvas = branding => {
    // PUSH BRANDING DATA TO HTML ELEMENTS IN CANVAS
    //document.querySelector('.tb-logo-sub-title').innerHTML = branding.logoTitle.val;

    // $('.logo-image img').attr('src', branding.logoImage.src);
    $('#fsHeader .logo-image img').attr('src', branding.logoImage.src);
    $('.logo-title .fsLocationName').html(branding.logoTitle.val);
    $('.logo-title .fsLocationMotto').html(branding.logoSubtitle.val);

}

export const getOriginalHeaderData = (branding) => {

    //$('.logo-image img').removeAttr('data-image-sizes data-resource-uuid');

    const imageSrc = $('#fsHeader').find('.logo-image img').attr('src');
    const logoTitle = $('#fsHeader').find('.logo-title .fsLocationName').eq(0).text().trim();
    const logoSubtitle = $('#fsHeader').find('.logo-title .fsLocationMotto').eq(0).text().trim();


    const ifSrc = branding.logoImage.customSrc ? branding.logoImage.customSrc : imageSrc;
    const ifTitle = branding.logoTitle.customVal ? branding.logoTitle.customVal : logoTitle;
    const ifSubtitle = branding.logoSubtitle.customVal ? branding.logoSubtitle.customVal : logoSubtitle;

    const newHeaderBranding = {
        currentSrc: ifSrc,
        originalSrc: imageSrc,
        currentTitle: ifTitle,
        originalTitle: logoTitle,
        currentSubtitle: ifSubtitle,
        originalSubtitle: logoSubtitle
    }
    
    return newHeaderBranding
}