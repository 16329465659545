import React, { useState, useEffect, useContext } from 'react'

import ReactTooltip from "react-tooltip"

import CustomButton from '../../ReusableComponents/CustomButton/CustomButton'

import ModalCloseButton from '../../ReusableComponents/ModalCloseButton/ModalCloseButton'

import { PanelContext } from '../../../providers/panels/panel.provider'
import { UserContext } from '../../../providers/user/user.provider'

import $ from 'jquery'

import './ModalExport.scss'

const ModalExport = () => {
    ReactTooltip.rebuild();

    const [exportTemplate, setExportTemplate] = useState('');
    //const [copied, setCopied] = useState(false)

    const [success, setSuccess] = useState(false);

    const { renderedBranding, renderedColumns, panelData, currentProject, handleModalClass } = useContext(PanelContext)
    const { currentUser } = useContext(UserContext);

    let panelIndex = 0;

    // get output for importing/sharing project
    // console.log([currentProject, renderedColumns.canvas, renderedBranding]);

    useEffect(()=>{

        //setCopied(false);

        let text = '';
        let panelIndex = 0;

        text += "----------------\nPanel Selections\n----------------\n\n";
        renderedColumns.canvas.panelIds.forEach((panel => {
            if(panelData[panel].folder !== "styleguides" && panelData[panel].folder !== "mobilemenus"){
                panelIndex++
                return text += panelIndex + '. ' + panel + "\n";
            }
            return
        }))

        text += "\n----------------\nColor Selections\n----------------\n\n"

        renderedBranding.colors.forEach((color => {
            return text += color.label + ': ' + color.value + "\n";
        }))

        text += "\n----------------\nFont Selections\n----------------\n\n"

        renderedBranding.fonts.forEach((font => {
            return text += font.label + ': ' + font.value + "\n";
        }))

        text += "\n----------------\nBranding\n----------------\n\n"

        if(renderedBranding.logoTitle.display){
            text += "Logo Title: " + renderedBranding.logoTitle.val + "\n\n"
        }

        if(renderedBranding.logoSubtitle.display){
            text += "Logo Subtitle: " + renderedBranding.logoSubtitle.val + "\n\n"
        }

        if(renderedBranding.district){
            text += "District: " + renderedBranding.district + "\n\n"
        }
        
        if(renderedBranding.accreditations){
            text += "Accreditations: " + renderedBranding.accreditations + "\n\n"
        }
        

        setExportTemplate(text);

    },[]); // eslint-disable-line react-hooks/exhaustive-deps
    

    // const handleCopyText = (e) => {
	// 	if (document.queryCommandSupported && document.queryCommandSupported('copy')) {
	// 			$('.export-text-area').select()
    //             document.execCommand("copy");
                
    //             setCopied(true);
	// 	}
    // }

    const copyObjectValue = JSON.stringify([currentProject, renderedColumns.canvas, renderedBranding]);

    const copyClipboard = () => {
        /* Get the text field */
        const copyObject = document.getElementsByClassName('export-container')[0];
      
        /* Select the text field */
        copyObject.select();
        copyObject.setSelectionRange(0, 99999); /* For mobile devices */
      
         /* Copy the text inside the text field */
        navigator.clipboard.writeText(copyObject.value);
      
        /* Alert the copied text */
        // alert("Copied Code for Export");
    }
    
    const handleSendExport = () => {
        let projectSelections = $('.text-area-container').html();
        let exportTitle = currentProject;

        let exportData = {
            exportTitle,
            projectSelections,
            type: "export",
            to: currentUser.currentUser.email,
            name: currentUser.currentUser.displayName
        }

        // console.log(exportData);
        // console.log(currentProject);


        $.post('/api/mailExport', exportData, function (res) {
            if(res){
                setSuccess(true);
            }
        });
    }

    const handleCloseModal = () => {
        handleModalClass('');
        setSuccess(false);
    }

    if (success) {
        return (
            <>  
                <ModalCloseButton onClick={handleCloseModal}>Close</ModalCloseButton>
                <p>Your configuration has been successfully sent to Finalsite, and a copy was emailed to you.</p>
            </>
        )
    } else {
        return (
            <>
                <ModalCloseButton onClick={handleCloseModal}>Close</ModalCloseButton>
                <div className="tb-export-title">{currentProject}</div>
                <div className="tb-export-main-content">
                    <div className="tb-export-content-1">
                        <div className="tb-export-section">
                            <h3>THEME SECTIONS</h3>
                            {
                                (renderedColumns.canvas.panelIds.length >= 9) &&
                                <div className="export-alert">
                                    This layout has more than four sections.
                                </div>
                            }
    
                            <div className="tb-export-section-panels">
                                {
                                    renderedColumns.canvas.panelIds.map(((panel, i) => {
                                        if(panelData[panel].folder !== "styleguides" && panelData[panel].folder !== "mobilemenus"){
    
                                            if(panelData[panel].folder === "headers"){
                                                return <div className="tb-export-panel-item" key={i}><div className="count-container"><span className="tb-export-panel-count">H</span></div>{panelData[panel].title}</div>;
                                            }
                                            if(panelData[panel].folder === "footers"){
                                                return <div className="tb-export-panel-item" key={i}><div className="count-container"><span className="tb-export-panel-count">F</span></div>{panelData[panel].title}</div>;
                                            }
                                            panelIndex++
                                            return <div className="tb-export-panel-item" key={i}><div className="count-container"><span className="tb-export-panel-count">{panelIndex}</span></div>{panelData[panel].title}</div>;
                                        }
                                    }))
                                }
                            </div>
                        </div>
                    </div>
                    <div className="tb-export-content-2">
                        <div className="tb-export-section">
                            <h3>THEME COLORS</h3>
                            <div className="tb-export-section-group">
                                <div className="tb-export-section-inner">
                                    <div className="tb-export-color" style={{ background: `${renderedBranding.colors[0].value}` }}></div>
                                    <div className="tb-export-display-main">{renderedBranding.colors[0].value}</div>
                                    <div className="tb-export-display-secondary">{renderedBranding.colors[0].label}</div>
                                </div>
                                <div className="tb-export-section-inner">
                                    <div className="tb-export-color" style={{ background: `${renderedBranding.colors[1].value}` }}></div>
                                    <div className="tb-export-display-main">{renderedBranding.colors[1].value}</div>
                                    <div className="tb-export-display-secondary">{renderedBranding.colors[1].label}</div>
                                </div>
                            </div>
                        </div>
                        <div className="tb-export-section">
                            <h3>THEME FONTS</h3>
                            <div className="tb-export-section-group">
                                <div className="tb-export-section-inner">
                                    <div className="tb-export-display-main" style={{ fontFamily: `${renderedBranding.fonts[0].value}` }}>{renderedBranding.fonts[0].value}</div>
                                    <div className="tb-export-display-secondary">{renderedBranding.fonts[0].label}</div>
                                </div>
                                <div className="tb-export-section-inner">
                                    <div className="tb-export-display-main" style={{ fontFamily: `${renderedBranding.fonts[1].value}` }}>{renderedBranding.fonts[1].value}</div>
                                    <div className="tb-export-display-secondary">{renderedBranding.fonts[1].label}</div>
                                </div>
                            </div>
    
                        </div>
                        <div className="tb-export-section-branding">
                            <h3>THEME BRANDING</h3>
                            {renderedBranding.logoTitle.display && 
                            <div>
                                <div className="tb-export-display-main">{renderedBranding.logoTitle.val}</div>
                                <div className="tb-export-display-secondary">Logo Title</div>
                            </div>
                            }
                            {renderedBranding.logoSubtitle.display && 
                            <div>
                                <div className="tb-export-display-main">{renderedBranding.logoSubtitle.val}</div>
                                <div className="tb-export-display-secondary">Logo Subtitle</div>
                            </div>
                            }
                        </div>
                    </div>
                </div>
    
                <div className="tb-export-button-container">
                    <CustomButton className="tb-export-button" type='submit' onClick={()=>handleSendExport()}>Export</CustomButton>
                    <CustomButton className="tb-export-button keep-editing" type='submit' onClick={()=>handleModalClass('')}>Keep Editing</CustomButton>
                    <button data-tip data-for="copyTip" data-event="click" data-event-off="mouseout focusout" className="tb-share-button" type='submit' onClick={()=>copyClipboard()}><i className="icon-copy-solid" aria-hidden="true"></i>Share</button>
                </div>

                <ReactTooltip id="copyTip" place="top" effect="solid" delayHide={1000} afterShow={copyClipboard}>
                Copied!
                </ReactTooltip>
    
                <div className="text-area-container">
                    <pre>{exportTemplate}</pre>
                
                </div>
                <textarea 
                    className="export-container" 
                    defaultValue={copyObjectValue}
                    style={{display: 'none'}}
                ></textarea>
            </>
        )
    }
}

export default ModalExport
