export const initialProjectData = {
    columns: {
        'multi-content-panels': {
            id: 'multi-content-panels',
            title: 'Multi-Content Panels',
            panelIds: ['1-column-resource', '1-column-content-panel', '2-column-posts-calendar', '2-column-content', '2-column-twitter-facebook', '3-column-posts-calendar-twitter', '3-column-content', '3-column-twitter-facebook-buttons', '4-column-facebook-posts-calendar-buttons'],
            info: 'Multi-Content panels are available with 1, 2, 3 or 4-column layout options. They can be used to add additional content, resources, modules or embeds. All panels include an optional section title and element title(s).  The content shown is for demonstration purposes only and can be customized with your content once your site is built. Please review the site instructions for more details on how to configure.'
        },
        'headers': {
            id: 'headers',
            title: 'Headers',
            panelIds: ['header-1', 'header-2', 'header-3', 'header-4', 'header-5', 'header-6', 'header-7', 'header-8', 'header-9', 'header-10', 'header-11', 'header-12', 'header-13', 'header-14', 'header-15', 'header-16']
        },
        'footers': {
            id: 'footers',
            title: 'Footers',
            panelIds: ['footer-1', 'footer-2', 'footer-3', 'footer-4', 'footer-5', 'footer-6', 'footer-7', 'footer-8', 'footer-9', 'footer-10', 'footer-11', 'footer-12', 'footer-13']
        },
        'slideshows': {
            id: 'slideshows',
            title: 'Slideshows',
            panelIds: ['slideshow-1', 'slideshow-2', 'slideshow-3', 'slideshow-4', 'slideshow-5', 'slideshow-6', 'slideshow-7', 'slideshow-8', 'slideshow-9', 'slideshow-10', 'slideshow-11-v2', 'slideshow-12-v2', 'slideshow-13-v2', 'slideshow-14-v2']
        },
        'mission-statement': {
            id: 'mission-statement',
            title: 'Mission Statement',
            panelIds: ['mission-statement-1','mission-statement-2','mission-statement-3','mission-statement-4','mission-statement-5', 'mission-statement-6']
        },
        'post-panels': {
            id: 'post-panels',
            title: 'News',
            panelIds: ['post-panel-1', 'post-panel-2', 'post-panel-3', 'post-panel-4', 'post-panel-5', 'post-panel-6', 'post-panel-7', 'post-panel-8', 'post-panel-9', 'post-panel-10', 'post-panel-11', 'post-panel-12', 'post-panel-13']
        },
        'calendars': {
            id: 'calendars',
            title: 'Calendars',
            panelIds: ['calendar-1', 'calendar-2', 'calendar-3', 'calendar-4', 'calendar-5', 'calendar-6', 'calendar-7', 'calendar-8', 'calendar-9']
        },
        'post-calendar-panels': {
            id: 'post-calendar-panels',
            title: 'News & Calendars',
            panelIds: ['post-calendar-1', 'post-calendar-2', 'post-calendar-3', 'post-calendar-4', 'post-calendar-5', 'post-calendar-6', 'post-calendar-7', 'post-calendar-8', 'post-calendar-9', 'post-calendar-10', 'post-calendar-11', 'post-calendar-12', 'post-calendar-13']
        },
        'testimonials': {
            id: 'testimonials',
            title: 'Testimonials',
            panelIds: ['testimonial-1', 'testimonial-2', 'testimonial-3', 'testimonial-4', 'testimonial-5']
        },
        'infographics': {
            id: 'infographics',
            title: 'Infographics',
            panelIds: ['infographic-1','infographic-2','infographic-3','infographic-4','infographic-5']
        },
        'image-marquees': {
            id: 'image-marquees',
            title: 'Image Marquees',
            panelIds: ['image-marquee-1','image-marquee-2','image-marquee-3','image-marquee-4']
        },
        'showcases': {
            id: 'showcases',
            title: 'Showcases',
            panelIds: ['showcase-1','showcase-2','showcase-3','showcase-4','showcase-5','showcase-6','showcase-7']
        },
        'icon-panels': {
            id: 'icon-panels',
            title: 'Icon Panels',
            panelIds: ['icon-panel-1', 'icon-panel-2', 'icon-panel-3', 'icon-panel-4', 'icon-panel-5', 'icon-panel-6', 'icon-panel-7', 'icon-panel-8', 'icon-panel-9', 'icon-panel-10'],
            info: "All icon panels can display additional icons that will wrap to the next row beyond the maximum that you see displayed. If you prefer to customize this even further, you have the option to use an additional icon element that can display a specific number of icons in each row."
        },
        'button-panels': {
            id: 'button-panels',
            title: 'Button Panels',
            panelIds: ['button-panel-1', 'button-panel-2', 'button-panel-3','button-panel-4', 'button-panel-5','button-panel-6', 'button-panel-7']
        },
        'social-panels': {
            id: 'social-panels',
            title: 'Social Media (Finalsite Feeds)',
            panelIds: ['social-panel-1', 'social-panel-2', 'social-panel-3'],
            info: "These panels are powered by the Finalsite Feeds module. This is an optional add-on module that may or may not be included in the package that was purchased for your organization. Please contact your Deployment Project Manager or Client Success Manager to confirm or add it to your contract."
        },
        'canvas': {
            id: 'canvas',
            title: 'Canvas',
            panelIds: ['styleguide', 'mobilemenu']
        }
    },
    branding: {
        "colors": [
            {
                "name": "m1",
                "label": "Primary Color",
                "value": "#cc4100",
            },
            {
                "name": "m2",
                "label": "Accent Color",
                "value": "#72bfd7",
            }
        ],
        "fonts": [
            {
                "name": "font-family-1",
                "label": "Font 1",
                "value": "Open Sans",
                "options": [
                    { 'name': 'Open Sans' },
                    { 'name': 'Lato' },
                    { 'name': 'Bree Serif' },
                    { 'name': 'Zilla Slab' },
                    { 'name': 'Droid Serif' },
                    { 'name': 'Roboto Slab' },
                    { 'name': 'Merriweather' },
                    { 'name': 'Kurale' },
                    { 'name': 'Bitter' },
                    { 'name': 'Arvo' }
                ]
            },
            {
                "name": "font-family-2",
                "label": "Font 2",
                "value": "EB Garamond",
                "options": [
                    { 'name': 'EB Garamond' },
                    { 'name': 'Lato' },
                    { 'name': 'Bree Serif' },
                    { 'name': 'Zilla Slab' },
                    { 'name': 'Droid Serif' },
                    { 'name': 'Roboto Slab' },
                    { 'name': 'Merriweather' },
                    { 'name': 'Kurale' },
                    { 'name': 'Bitter' },
                    { 'name': 'Arvo' }
                ]
            }
        ],
        logoImage: {
            display: true,
            src: '',
            originalSrc: '',
            customSrc: null
        },
        logoTitle: {
            display: true,
            val: '',
            originalVal: '',
            customVal: null
        },
        logoSubtitle: {
            display: true,
            val: '',
            originalVal: '',
            customVal: null
        },
        district: false,
        accreditations: false
    }
}

export const columnOrderdata = ['headers', 'footers', 'slideshows', 'mission-statement', 'post-panels', 'calendars', 'post-calendar-panels', 'testimonials', 'infographics', 'showcases', 'icon-panels', 'button-panels', 'social-panels', 'canvas'];
