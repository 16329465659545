import React from 'react'
import Logo from '../Logo/Logo'
import CanvasTabs from '../CanvasTabs/CanvasTabs'
import UtilityNav from '../UtilityNav/UtilityNav'

import './Header.scss'

const Header = () => (
    <header className="tb-header-container">
        <div className="tb-header-column flex-column">
            <Logo />
        </div>
        <div className="tb-header-column">
            <CanvasTabs />
        </div>
        <div className="tb-header-column">
            <UtilityNav />
        </div>
    </header>
);


export default React.memo(Header);