export const CLOSE_MODAL = 'CLOSE_MODAL';
export const REMOVE_PANEL = 'REMOVE_PANEL';
export const DRAG_END = 'DRAG_END';
export const ADD_EMPTY_PROJECT = 'ADD_EMPTY_PROJECT';
export const UPDATE_BRANDING = 'UPDATE_BRANDING';
export const COMBINE_PROJECT = 'COMBINE_PROJECT';
export const SET_CURRENT_PROJECT = 'SET_CURRENT_PROJECT';
export const SET_USER_PROJECTS = 'SET_USER_PROJECTS';
export const ADD_ADDITIONAL_PROJECT = 'ADD_ADDITIONAL_PROJECT';
export const PARSE_PROJECT = 'PARSE_PROJECT'; // project data only has branding/columns.canvas - needs to be parsed first and create a real columns object based on just the canvas. this will also set ready to save as false
export const GET_ORIGINAL_HEADER_DATA = 'GET_ORIGINAL_HEADER_DATA';