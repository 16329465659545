import React, { useState, useEffect, useContext } from 'react'
import { Draggable } from 'react-beautiful-dnd'
import $ from 'jquery';

import PanelPortal from '../PanelPortal/PanelPortal'

import { PanelContext } from '../../../providers/panels/panel.provider'

import './PanelItem.scss'

const PanelItem = ({panel, column, index}) => {
    
    const { disableDrag, renderedColumns } = useContext(PanelContext);
    

    const canvasPanels = renderedColumns.canvas.panelIds;
    const isDragDisabled = (panel.id === 'styleguide' || panel.id === 'mobilemenu' || panel.id === 'mobilemenu-2') || !!(panel.folder === "headers" && canvasPanels.find(a => a.includes("header"))) || !!(panel.folder === "footers" && canvasPanels.find(a => a.includes("footer"))) || disableDrag;
    
    

    return (

        <Draggable
            draggableId={panel.id}
            index={index}
            isDragDisabled={isDragDisabled}
        >
            {(provided, snapshot)=>(
                <PanelPortal
                    panel={panel}
                    provided={provided}
                    snapshot={snapshot}
                    column={column}
                />
            )}
        </Draggable>
    )
}

export default PanelItem
