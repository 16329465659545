import React from 'react'

import './ToggleCheckbox.scss'

const ToggleCheckbox = (props) => {
    const { linkedTo, defaultChecked, onChange } = props;
    return (
        <>
            <input type="checkbox"
                id={linkedTo}
                className="checkbox-livedemo"
                style={{ display: "none" }}
                checked={defaultChecked}
                
                onChange={onChange}
            />
            <label htmlFor={linkedTo} className="toggle-checkbox"><span></span></label>
        </>
    )
}

export default ToggleCheckbox
