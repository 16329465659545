import React from 'react'

import './StylingNav.scss'

const StylingNav = ({title, classes, children}) => {
	const handleExpandSection = (evt) => {
		// <!--- toggle parent active class
        evt = evt || window.event;
        let targetParent = evt.target.parentElement || evt.srcElement.parentElement;
        let navElements = [...targetParent.parentElement.children];
		
		
        // # remove active then set active
        navElements.forEach(el=>{
            el.classList.remove("active");
        });
        targetParent.classList.add("active");
        // --!>
	}
	const handleCloseSection = (evt) => {
		evt = evt || window.event;
        let targetParent = evt.target.parentElement || evt.srcElement.parentElement;
		targetParent.parentElement.classList.remove("active");
	}
    return (
        <section className={`tb-nav-expander ` + classes}>
			<button className="tb-styling-button" onClick={()=>handleExpandSection(this)}>
                {title}
				<i className="icon-angle-right" aria-hidden="true"></i>
			</button>
			<div className="tb-nav-expander-content">
				<button className="tb-styling-button-back" onClick={()=>handleCloseSection(this)} >
					<i className="icon-angle-left" aria-hidden="true"></i>
					{title}
				</button>
				<div className="tb-style-content-container">
					<div className="tb-style-inner">
						{children}
					</div>
				</div>
			</div>
		</section>
    )
}

export default StylingNav
