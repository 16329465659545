import React, {useContext} from 'react'

import ColorPicker from './ColorPicker'

import { PanelContext } from '../../../providers/panels/panel.provider'

const ColorSection = () => {
    const { renderedBranding } = useContext(PanelContext);
    let colors = renderedBranding.colors;

    return <ColorMemo colors={colors} />
}

const ColorMemo = React.memo(function ColorMemo({colors}) {
    return (
        <div id="theme-colors">
            {colors.map((color, index) => (
                    <ColorPicker
                        name={color.name}
                        value={color.value}
                        label={color.label}
                        key={color.label}
                        index={index}
                    />
                )
            )}
        </div>
    )
});

export default ColorSection;
