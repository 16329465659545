import React, { useState } from 'react'

import FormInput from '../../ReusableComponents/FormInput/FormInput'
import CustomButton from '../../ReusableComponents/CustomButton/CustomButton'

import { auth, createUserProfileDocument } from '../../../firebase/firebase.utils'

import './SignUp.scss'

const SignUp = () => {

    const [credentials, setCredentials] = useState({
        displayName: '',
        email: '',
        password: '',
        confirmPassword: ''
    });

    const [error, setError] = useState();

    const handleSubmit = async event => {
        event.preventDefault();
        setError('');
        const { displayName, email, password, confirmPassword } = credentials;
    
        if (password !== confirmPassword) {
            setError("Passwords don't match");
            return;
        }
    
        try {
            const { user } = await auth.createUserWithEmailAndPassword(
                email,
                password
            );
    
            await createUserProfileDocument(user, { displayName });
    
            setCredentials({
                displayName: '',
                email: '',
                password: '',
                confirmPassword: ''
            });
        } catch (error) {
            console.error(error);
            if(error.code === "auth/weak-password"){
                setError(error.message);
            }
            if(error.code === "auth/email-already-in-use"){
                setError(error.message);
            }
        }
    };

    const handleChange = event => {
        const { name, value } = event.target;
    
        setCredentials({
            ...credentials,
            [name]: value
        });
    };

    return (
        <div className='tb-sign-up'>
            <div className="modal-title">Sign Up</div>
            <span>Sign up with your email and password</span>
            <form className='sign-up-form' onSubmit={handleSubmit}>
                <FormInput
                type='text'
                name='displayName'
                value={credentials.displayName}
                onChange={handleChange}
                label='Display Name'
                required
                />
                <FormInput
                type='email'
                name='email'
                value={credentials.email}
                onChange={handleChange}
                label='Email'
                required
                />
                <FormInput
                type='password'
                name='password'
                value={credentials.password}
                onChange={handleChange}
                label='Password'
                required
                />
                <FormInput
                type='password'
                name='confirmPassword'
                value={credentials.confirmPassword}
                onChange={handleChange}
                label='Confirm Password'
                required
                />
                {error &&
                <div className="sign-up-error">{error}</div>
                }
                <CustomButton className="tb-modal-button" type='submit'>SIGN UP</CustomButton>
            </form>
      </div>
    )
}

export default SignUp
