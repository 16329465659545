import React, { useContext } from 'react'

import DragDropCtaImage from '../../../assets/gui-cta/drag-drop-img.png'

import PanelItem from '../PanelItem/PanelItem'

import { PanelContext } from '../../../providers/panels/panel.provider'

import './PanelList.scss'



const PanelList = ({column}) => {
    const { panelData } = useContext(PanelContext);
    const currentPanels = column.panelIds.map(panelId => panelData[panelId]);

    if (column.id ==="canvas") {
        return (
            (column.panelIds.length > 3 && column.panelIds.includes('mobilemenu-2')) || (column.panelIds.length > 2 && !column.panelIds.includes('mobilemenu-2')) ?
            currentPanels.map((panel, index) => (
                <PanelItem key={panel.id} column={column} panel={panel} index={index} />
            )) :
            
            <div className="drag-drop-cta">
                <img src={DragDropCtaImage} alt="Drag items from the ADD section on the left over to here to start building your site" />
                <div className="panel-instructions">
                    <div className="panel-instructions-title">Drag and drop.</div>
                    <p>To begin, drag and drop a section from the left into this space. Then apply colors, fonts, logos, and more in the Style tab.</p>
                    {/* <p>Need help? <a href="#">Connect with our team</a> or browse our <a href="#">FAQ’s.</a></p> */}
                </div>
            </div>

            
        )
    }

    return (

        column.panelIds.length > 0 ?
        currentPanels.map((panel, index) => (
            <PanelItem key={panel.id} column={column} panel={panel} index={index} />
        )) :
        <div className="panel-list-empty">All panels in this section have been added. Please go back to choose panels from a different section.</div>
        
    )
}

export default PanelList
