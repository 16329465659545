import React, {useState, useContext } from 'react'

import CustomButton from '../../ReusableComponents/CustomButton/CustomButton'
import ModalCloseButton from '../../ReusableComponents/ModalCloseButton/ModalCloseButton'
import FormInput from '../../ReusableComponents/FormInput/FormInput'

import { deleteUser } from '../../../firebase/firebase.utils'

import { PanelContext } from '../../../providers/panels/panel.provider'

import { auth } from '../../../firebase/firebase.utils'

import './ModalDeleteUser.scss';


const ModalDeleteUser = () => {

    const confirmation = "DELETE";
    const [input, setInput] = useState('');
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(null);

    const { handleModalClass, handleParseProject, initialProjectObj } = useContext(PanelContext);

    const handleChange = event => {
        const { value } = event.target;
        setInput(value);
        setError('');
    };

    const handleDeleteUser = (event) => {
        event.preventDefault();
        setError('');

        if(input.toUpperCase() === confirmation.toUpperCase()) {
            async function sendDelete() {
                const result = await deleteUser();
                //console.log(result) and get error for session bug
                if(result){
                    auth.signOut().then(function() {
                        setSuccess(true)
                        handleParseProject(initialProjectObj);
                        
                      }, function(error) {
                        console.log(error)
                      });
                    
                }
            }
            sendDelete();
        } else {
            setError("Input doesnt match DELETE");
        }
    }

    const handleCloseModal = () => {
        handleModalClass('');
        setSuccess(false);
    }

    return (
        <>
            <ModalCloseButton onClick={handleCloseModal}>Close</ModalCloseButton>
            <div className="modal-title">Delete Account</div>
            {
                // modal is closing before this success ternary happens
                success ? <p>User has been deleted successfully</p> :
            <>
                <p>Please type <span>DELETE</span> in the box below to confirm</p>
                <div>
                    <form onSubmit={handleDeleteUser}>
                        <FormInput
                            name='text'
                            type='text'
                            handleChange={handleChange}
                            value={input}
                            label=''
                            required
                        />
                        {error !== null && (
                            <div className="delete-user-error">
                            {error}
                            </div>
                        )}
                        <CustomButton className="tb-modal-button" type='submit'> Delete Account </CustomButton>
                    </form>
                </div>
            </>
            }
        </>
    )
}

export default ModalDeleteUser
