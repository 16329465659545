import React, { useContext, useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
import $ from 'jquery';
import { PanelContext } from '../../../providers/panels/panel.provider'

//import '../../../providers/panels/panel-jquery-utils/scrollPageOnDrag'

import './PanelPortal.scss'


//Create div portal for dragging items that are scaled, to show position properly
const portal = document.createElement('div');
portal.classList.add('dragging-portal');
document.body.appendChild(portal);

const PanelPortal = ({provided, snapshot, panel, column}) => {
    
    const [html, setHtml] = useState('');
    const [isLoaded, setIsLoaded] = useState(false);
    
    const { handleRemovePanel, handleLoadJS, windowThemeHeaderData } = useContext(PanelContext);
    
    const usePortal = snapshot.isDragging;

    useEffect(()=>{
        if(usePortal) {
            setHtml('');
            if ($("head script[data-js-panel*=" + panel.id + "]").length) {
                $("head script[data-js-panel*=" + panel.id + "]").remove();
            }
        }else{
            if(column.id === "canvas"){
                awaitHtml(panel);
            }
        }

    },[usePortal])
    
    const awaitHtml = panel => {
        fetch('/api/panel/' + panel.id)
        .then(response => response.text())
        .then(function (text) {
            var parser = new DOMParser();
            var doc = parser.parseFromString(text, "text/html");
            var element;

            // setting the starting point to the proper element
            // if styleguide, grab fsPageBodyWrapper instead
            if (panel.folder === "styleguides") {
                element = doc.getElementById('fsPageBodyWrapper');
            } else {
                element = doc.getElementById('fsEl_' + panel.elementId);
            }

            // Create #fsHeader and .fsHeader divs (same for footers)
            if (panel.folder === "headers") {
                $(element).wrap('<div id="fsHeader" class="fsHeader"><div class="fsBanner"></div></div>');
                element = doc.getElementById('fsHeader');
                // # Set the window.theme.branding data to reflect the logo of the header
            }
            if (panel.folder === "footers") {
                $(element).wrap('<div id="fsFooter" class="fsFooter"><div class="fsBanner"></div></div>');
                element = doc.getElementById('fsFooter');
            }
            if (panel.folder === "mobilemenus") {
                $(element).wrap('<div id="fsMenu"><div class="fsMenu"></div></div>');
                element = doc.getElementById('fsMenu');
            }

            //run function that moves the data image size to the src
            var images = doc.querySelectorAll('[data-image-sizes]');
            $(images).each(function () {
                var _ = $(this);
                var sizeData = JSON.parse(unescape(_.attr('data-image-sizes')));
                _.attr('src', sizeData[sizeData.length - 1].url);
            })


           
            // take elements and convert back to text string so react can render as HTML (seems backwards but needed)
            const elementToString = element.outerHTML;
            setHtml(elementToString); 
        })
        .catch(function (err) {
            console.log('Failed to fetch page: ', err);
        });
    }

    // useEffect(()=>{
        
    // },[]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(()=>{
        if(html){
            async function isItLoaded() {
                try {
                    await handleLoadJS(panel);

                    setTimeout(()=>{setIsLoaded(true)},1200);
                    // setIsLoaded(true)

                } catch(error) {
                    console.log(error);
                }
            }

            isItLoaded();

            // if header panel, set original logo data, and if current is modified etc.
            if (panel.folder === "headers") {
                windowThemeHeaderData();
            }

            // Links inside canvas should default none

            $('.tb-canvas-inner').click('a', function(e){
                e.preventDefault();
            });
        }else{
            setIsLoaded(false);
        }
    },[html]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleHTMLFetch = () => {
        // <button onClick={()=>handleHTMLFetch()}>fetch</button> FETCH HTML this gets added
        const id = panel.elementId;
        const title = panel.id;

        const options = { id,
            title
        }
        
        $.post('/api/saveHtml/', options, function (res) {
        });
    }


    

    const child = (
        <div
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
            className={"panel-wrap " + (usePortal ? "isDragging" : "")}
            key={panel.elementId}
            data-title={panel.title}
        >
            <button className="remove-panel icon-bin" onClick={()=>handleRemovePanel(panel)}>Remove</button>
            
            {
                !snapshot.isDropAnimating && 
                <img className="preview-image" alt={panel.title}
                    src={require(`./../../../assets/panel-previews/${panel.id}/${panel.id}.png`)}
                />
            }
            {
                !isLoaded && <div className="loader"><div className="lds-ring"><div></div><div></div><div></div><div></div></div></div>
            }

            {
                !usePortal &&
                <div
                    className={`panel ${(isLoaded ? 'loaded' : 'hidden')}`}
                    dangerouslySetInnerHTML={{ __html: html }}
                >
                </div>
            }
            
            
            
        </div>
    );

    if(!usePortal) {
        return child;
    } else {
        return ReactDOM.createPortal(child, portal);
    }
}

export default PanelPortal
