import React from 'react'

import StylingNav from './StylingNav/StylingNav'
import ColorSection from '../../Branding/ColorsSection/ColorSection'
import FontSection from '../../Branding/FontSection/FontSection'
import BrandingSection from '../../Branding/BrandingSection/BrandingSection'
import DistrictSection from '../../Branding/DistrictSection/DistrictSection'
import AccreditationsSection from '../../Branding/AccreditationsSection/AccreditationsSection'

import './StylingSection.scss'

const StylingSection = () => {
    return (
		<div className="tb-styling-section">
			<StylingNav title="Theme Colors" classes="styling-section-colors">
				<ColorSection />
			</StylingNav>
			<StylingNav title="Theme Fonts">
				<FontSection />
			</StylingNav>
			<StylingNav title="Theme Branding">
				<BrandingSection />
			</StylingNav>
			<StylingNav title="Theme District">
				<DistrictSection />
			</StylingNav>
			<StylingNav title="Theme Accreditations">
				<AccreditationsSection />
			</StylingNav>
			
		</div>
    )
}

export default StylingSection