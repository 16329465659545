import React, { useState, useEffect, useContext } from 'react'

import { PanelContext } from '../../../providers/panels/panel.provider'
import { UPDATE_BRANDING } from '../../../providers/panels/panel.types';

import './FontPicker.scss'

const FontPicker = (props) => {
    const { value, label, options, index } = props;

    
    const labelid = label.replace(/\./g,'');
    
    const { renderedBranding, dispatch } = useContext(PanelContext);

    const newFontsArray = Array.from(renderedBranding.fonts);
    
    //used to be conditional storage
    const [currentSelection, setCurrentSelection] = useState(value); 
    const [additionalOptions, setAdditionalOptions] = useState(options);
    
    const [showingCustom, setShowingCustom] = useState(false);
    const [fontValidation, setFontValidation] = useState(true);
    const [customFontField, setCustomFontField] = useState('');
    

    
    const selectTheFont = e => {
        setTheFont(e.target.value);
    }

    const setTheFont = font => {

        if (font !== "custom") {
            setShowingCustom(false);
            setCurrentSelection(font);
        } else {
            setShowingCustom(true);
            setCurrentSelection('custom');
        }
    }

    const handleReset = () => {
        setCurrentSelection(value)
    }

    const handleCustomInput = e => {
        let val = e.target.value.replace(/\b\w/g, function (l) { return l.toUpperCase() });
        setCustomFontField(val);
    }

    const handleFontRequest = e => {
        e.preventDefault();

        const value = customFontField;
        const fontUrl = "//fonts.googleapis.com/css?family=" + value.replace(' ', '+');
        
        if(customFontField.length === 0) {
            setFontValidation(false);
            return false;
        }
        fetch(fontUrl).then(response => {
            if(response.ok){
                setFontValidation(true);
                handleAddFont();
            } else {
                setFontValidation(false);
            }
        })
        .catch(error => {
            setFontValidation(false);
        });
    }

    const handleAddFont = () => {
        const newFontToAdd = {};
        newFontToAdd.name = customFontField;
        const combinedAdditionalOptions = additionalOptions.concat(newFontToAdd);
        setAdditionalOptions(combinedAdditionalOptions);
        setTheFont(customFontField);
    }
    

    // push to global branding data
    useEffect(() => {
        const pushNewBranding = () => {
            newFontsArray[index].value = currentSelection;
            newFontsArray[index].options = additionalOptions;
            

            // console.log('additional options', additionalOptions);
            const updatedBranding = {
                ...renderedBranding,
                fonts: newFontsArray
            }

            
            dispatch({type: UPDATE_BRANDING, payload: updatedBranding})
        }
        pushNewBranding();

    }, [currentSelection]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setCurrentSelection(renderedBranding.fonts[index].value);
        
    }, [renderedBranding.fonts[index].value]) // eslint-disable-line react-hooks/exhaustive-deps
    

    return (
        <div className="font-field">
            {label && <h4>{label}</h4>}
            <div className="font-field-inner">
            <div className="select-wrap">
                <label className="hide" htmlFor={labelid} />
                <select
                    value={currentSelection}
                    onChange={(e) => selectTheFont(e)}
                    id="labelid"
                >
                    {additionalOptions.map((option) => (
                        <option key={option.name} value={option.name}>{option.name}</option>
                    ))}
                    <option value="custom">Other&hellip;</option>
                </select>
            </div>
                {/*
                    (value !== currentSelection) &&
                    <button className="reset-fonts icon-spinner1" type="button" onClick={handleReset}><div className="tb-hide">Reset Font</div></button>
                */}
            </div>
            {
                showingCustom &&
                <form className="custom-font">
                    <input type="text"
                        className={fontValidation ? 'input-ready' : 'input-error'}
                        onChange={handleCustomInput}
                    />
                    <input type="submit"
                        value="Add"
                        onClick={handleFontRequest}
                    />
                </form>
            }
        </div>
    )
}

export default FontPicker
