import React, { useState, useEffect, useContext } from 'react'
import { PhotoshopPicker } from 'react-color'
import { hex } from 'wcag-contrast'

import useDebounceHook from '../../ReusableComponents/useDebounceHook/useDebounceHook'

import { PanelContext } from '../../../providers/panels/panel.provider'

import { UPDATE_BRANDING } from '../../../providers/panels/panel.types'


// INITIAL PROJECT DATA IS BEING CHANGED IN GUEST MODE SOMEHOW. SET A DIFF COLOR OBJ THAT WONT BE CHANGED IF ALL ELSE FAILS
//import { initialProjectData } from '../../../providers/panels/initialdata/initial.project.data'

import './ColorPicker.scss'

const ColorPicker = (props) => {

    const { value, label, index } = props;
    
    const { renderedBranding, initialProjectData, dispatch } = useContext(PanelContext);


    //const defaultColor = !currentUser.currentUser ? '#f00' : initialProjectData.branding.colors[index].value;
    const defaultColor = initialProjectData.branding.colors[index].value;

    const [toggleColorPicker, setToggleColorPicker] = useState(false);
    // const [cancelColorPicker, setCancelColorPicker] = useState(false);

    const [color, setColor] = useState(value);
    const [colorRevert, setColorRevert] = useState(value);

    const [contrastError, setContrastError] = useState(false);
    const [contrastErrorSecondary, setContrastErrorSecondary] = useState(false);
    const [contrastRatio, setContrastRatio] = useState(0);
    
    const debounceColor = useDebounceHook(color, 500);

    // Value is correct. need to keep color updated with value, but it fucks up both projects

    const pushNewBranding = () => {
        
        if(debounceColor) {
            const newColorArray = Array.from(renderedBranding.colors);
            newColorArray[index].value = color;

            const updatedBranding = {
                ...renderedBranding,
                colors: newColorArray
            }

            dispatch({type: UPDATE_BRANDING, payload: updatedBranding});
            
        }
    }
    
    useEffect(()=>{
        pushNewBranding();
    },[debounceColor]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(()=>{
        setColor(value);
        contrastCheck(value, '#f8f8f8');
    },[value]); // eslint-disable-line react-hooks/exhaustive-deps

    // const handleSwatchColorOnProjectChange = () => {
    //     setColor(value);
    // }

    const handleClick = () => {
        setColorRevert(color);
        setToggleColorPicker(!toggleColorPicker);
    }
    
    const handleClose = () => {
        setToggleColorPicker(false);
    }
    
    const handleReset = () => {
        setColor(colorRevert);
        setToggleColorPicker(false);
    }

    const handleChange = (selectedColor) => {
        setColor(selectedColor.hex);
    }

    const handleChangeComplete = (selectedColor) => {
        setColor(selectedColor.hex);
        contrastCheck(selectedColor.hex, '#f8f8f8');
        
    }

    const contrastCheck = (selectedColor, against) => {
        
        // dont check contrast on m2, its now just a highlight color
        // if(index === 1) {
        //     return false;
        // }
        var CheckRatio = hex(selectedColor, against);

        // primary color
        if(index === 0) {
            if (CheckRatio > 4.5) {
                setContrastError(false);
            } else {
                setContrastError(true);
                setContrastRatio(CheckRatio);
            }
        }

        // secondary color
        if(index === 1) {
            if (CheckRatio > 1.5) {
                setContrastErrorSecondary(false);
            } else {
                setContrastErrorSecondary(true);
                setContrastRatio(CheckRatio);
            }
        }
    }
    
    

    return (
        <div className="color-field">
            <div className="color-label-reset-container">
                <div className="color-label">{label}</div>
                {/*
                    (defaultColor !== color) &&
                    <button className="reset-colors icon-spinner1" type="button" onClick={handleReset}><div className="tb-hide">Reset Color</div></button>
                */}
            </div>
            <div className="color-inner-field">
                <div className={"swatch " + (contrastError ? 'failAA' : '')} onClick={handleClick}>
                    <div className="swatch-color" style={{ background: `${color}` }} />
                </div>
                
                <div className={"color-hex " + (contrastError ? 'hexFailAA' : '')}>
                    {color}
                </div>
                
            </div>

            {
                contrastError && <div className="contrast-error">Color must pass accessibility contrast ratio over 4.5. Current color contrast ratio is {Math.round((contrastRatio + Number.EPSILON) * 100) / 100}</div>
            }
            {
                contrastErrorSecondary && <div className="contrast-error">Although the secondary color is not required to pass any minimum contrast ratio for accessibility, using very light colors may result in visibility concerns for certain design elements.</div>
            }

            {
            toggleColorPicker ? 
            <div className="popover">
                {/* <div className="cover" onClick={handleClose} /> */}
                {/* <div className="cover" /> */}
                <PhotoshopPicker color={color} onChange={handleChange} onChangeComplete={handleChangeComplete} onAccept={handleClose} onCancel={handleReset}/>
            </div> : 
            null}
        </div>
    )
}

export default ColorPicker
