import React from 'react'

import './ModalFonts.scss'

const ModalFonts = ({handleModalClass}) => {
    return (
        <>
            <div className="tb-modal-header">Adding a Custom Font</div>
            <div className="tb-modal-body">
                <ol>
                    <li>Navigate to <a href="https://fonts.google.com/" className="livedemo-link" rel="noopener noreferrer" target="_blank">Google Fonts</a> and choose a font.</li>
                    <li>Click on the font to go to the font's page, then copy full font name from that page.</li>
                    <li>Back in the Theme Builder, select "Other…" from one of the font dropdowns</li>
                    <li>Paste the font name into the new field provided and click "Add"</li>
                </ol>
            </div>
            <button onClick={()=>handleModalClass('')} className="modal-close-button">Close</button>
        </>
    )
}

export default ModalFonts
