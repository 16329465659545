import React, {useState, useContext } from 'react'

import FormInput from '../../ReusableComponents/FormInput/FormInput'
import CustomButton from '../../ReusableComponents/CustomButton/CustomButton'
import ModalCloseButton from '../../ReusableComponents/ModalCloseButton/ModalCloseButton'

import { auth, signInWithGoogle } from '../../../firebase/firebase.utils'

import { PanelContext } from '../../../providers/panels/panel.provider'

import './ModalForgotPassword.scss'

const ModalForgotPassword = () => {


    const [email, setEmail] = useState("");
    const [emailHasBeenSent, setEmailHasBeenSent] = useState(false);
    const [error, setError] = useState(null);
    const [ifGoogle, setIfGoogle] = useState(null);

    const { handleModalClass } = useContext(PanelContext);
    
    const handleChange = event => {
        const { value } = event.target;
        setEmail(value);
    };

    const sendResetEmail = event => {
        event.preventDefault();
        setError('');
    
        //Check to see if account is email+password sign up, if not, error
        auth.fetchSignInMethodsForEmail(email).then(function(signInMethods) {
            if(signInMethods.includes("password")){
                auth
                .sendPasswordResetEmail(email)
                .then(() => {
                    setEmailHasBeenSent(true);
                    setTimeout(() => {setEmailHasBeenSent(false)}, 10000);
                })
                .catch(() => {
                    setError("This email address is not recognized");
                });
            } else {
                setError("This account was was created by signing in with google. Please use Sign in with Google");
                setIfGoogle(true);
            }
        });
        
    };

    return (
        <>
            <ModalCloseButton onClick={()=>handleModalClass('')}>Close</ModalCloseButton>
            <div className="tb-modal-header">Reset Your Password</div>
            <div className="reset-password-form-container">
                <form onSubmit={sendResetEmail}>
                    {emailHasBeenSent && (
                        <div className="reset-success">
                        A password reset email has been sent to you!
                        </div>
                    )}
                    {error !== null && (
                        <div className="reset-email-error">
                        {error}
                        </div>
                    )}
                    {ifGoogle ? 
                    <CustomButton type="button" className="tb-modal-button with-google" onClick={signInWithGoogle}>
                        Sign in with Google
                    </CustomButton>
                    :
                    <div>
                        <FormInput
                            name='email'
                            type='email'
                            handleChange={handleChange}
                            value={email}
                            label='email'
                            required
                        />
                        <CustomButton className="tb-modal-button" type='submit'> Send Reset Link </CustomButton>
                    </div>
                    }
                </form>
            </div>
        </>
    )
}

export default ModalForgotPassword
