import React, { useState, useEffect, useContext, useRef } from 'react'

import ToggleCheckbox from '../../ReusableComponents/ToggleCheckbox/ToggleCheckbox'

import { PanelContext } from '../../../providers/panels/panel.provider'
import { UPDATE_BRANDING } from '../../../providers/panels/panel.types';

import { ReactComponent as UploadLogo } from '../../../assets/uploadicon.svg';

import $ from 'jquery';

import './BrandingSection.scss'

function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
}

var dataURLToBlob = function(dataURL) {
    var BASE64_MARKER = ';base64,';
    if (dataURL.indexOf(BASE64_MARKER) === -1) {
        var parts = dataURL.split(',');
        var contentType = parts[0].split(':')[1];
        var raw = parts[1];

        return new Blob([raw], {type: contentType});
    }

    var parts = dataURL.split(BASE64_MARKER);
    var contentType = parts[0].split(':')[1];
    var raw = window.atob(parts[1]);
    var rawLength = raw.length;

    var uInt8Array = new Uint8Array(rawLength);

    for (var i = 0; i < rawLength; ++i) {
        uInt8Array[i] = raw.charCodeAt(i);
    }

    return new Blob([uInt8Array], {type: contentType});
}



const BrandingSection = () => {
    const { renderedBranding, handleBrandingToCanvas, dispatch } = useContext(PanelContext);

    
    
    const [newBrandingData, setNewBrandingData] = useState(renderedBranding);

    const prevBranding = usePrevious(renderedBranding);

    
    useEffect(()=>{
        // This updates when a new header also changes branding to push branding to fields in this componenygit
        if(JSON.stringify(prevBranding) !== JSON.stringify(renderedBranding)){
            setNewBrandingData(renderedBranding);
        }
    },[renderedBranding]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(()=>{
        
        dispatch({type: UPDATE_BRANDING, payload: newBrandingData});
        handleBrandingToCanvas(newBrandingData);
        
        if(newBrandingData.logoImage.display){
            // $('.logo-image').show(0);
            $('body').addClass('logo-active');
        } else {
            // $('.logo-image').hide(0);
            $('body').removeClass('logo-active');
        }

        if(newBrandingData.logoTitle.display){
            // $('.logo-title .fsLocationName').show(0);
            $('body').addClass('logo-title-active');
        } else {
            // $('.logo-title .fsLocationName').hide(0);
            $('body').removeClass('logo-title-active');
        }

        if(newBrandingData.logoSubtitle.display){
            // $('.logo-subtitle').show(0);
            // $('.logo-title .fsLocationMotto').show(0);
            $('body').addClass('logo-subtitle-active');
        } else {
            // $('.logo-subtitle').hide(0);
            // $('.logo-title .fsLocationMotto').hide(0);
            $('body').removeClass('logo-subtitle-active');
        }
    },[newBrandingData]); // eslint-disable-line react-hooks/exhaustive-deps

    //Logo
    const handleLogoDisplay = (e) => {
        
        setNewBrandingData({
            ...newBrandingData,
            logoImage: {
                ...newBrandingData.logoImage,
                display: e.target.checked
            }
        })
    }

    const handleImageSelect = (e, file) => {
        //try new file
        file = file || e.target.files[0];
        let pattern = /image-*/,
            reader = new FileReader();

        if (!file.type.match(pattern)) {
            alert('Invalid file type. Please choose an image file');
            return;
        }





        var resizedImage;

        
        reader.onload = (readerEvent) => {

            var image = new Image();
            image.onload = function (imageEvent) {

                // Resize the image
                var canvas = document.createElement('canvas'),
                    max_size = 500,// TODO : pull max size from a site config
                    width = image.width,
                    height = image.height;
                if (width > height) {
                    if (width > max_size) {
                        height *= max_size / width;
                        width = max_size;
                    }
                } else {
                    if (height > max_size) {
                        width *= max_size / height;
                        height = max_size;
                    }
                }
                canvas.width = width;
                canvas.height = height;
                canvas.getContext('2d').drawImage(image, 0, 0, width, height);
                var dataUrl = canvas.toDataURL('image/png');
                resizedImage = dataURLToBlob(dataUrl);
                $.event.trigger({
                    type: "imageResized",
                    blob: resizedImage,
                    url: dataUrl
                });
            }

            image.src = readerEvent.target.result;
            
        }

        reader.readAsDataURL(file);
        $(document).on("imageResized", function (event) {
            var data = event.url;
            
            if (event.blob && event.url) {
                
                setNewBrandingData({
                    ...newBrandingData,
                    logoImage: {
                        ...newBrandingData.logoImage,
                        src: data,
                        customSrc: data
                    }
                })
            }
        });
        
    }

    const handleImageRemove = () => {
        setNewBrandingData({
            ...newBrandingData,
            logoImage: {
                ...newBrandingData.logoImage,
                src: renderedBranding.logoImage.originalSrc,
                customSrc: null
            }
        })
    }

    //Title
    const handleTitleDisplay = (e) => {
        setNewBrandingData({
            ...newBrandingData,
            logoTitle: {
                ...newBrandingData.logoTitle,
                display: e.target.checked
            }
        })
    }

    const handleTitleInputChange = (e) => {
        setNewBrandingData({
            ...newBrandingData,
            logoTitle: {
                ...newBrandingData.logoTitle,
                val: e.target.value,
                customVal: e.target.value
            }
        })
    }

    const handleTitleRevert = () => {
        setNewBrandingData({
            ...newBrandingData,
            logoTitle: {
                ...newBrandingData.logoTitle,
                val: renderedBranding.logoTitle.originalVal,
                customVal: null
            }
        })
    }

    //Subtitle
    const handleSubtitleDisplay = (e) => {
        setNewBrandingData({
            ...newBrandingData,
            logoSubtitle: {
                ...newBrandingData.logoSubtitle,
                display: e.target.checked
            }
        })
    }

    const handleSubtitleInputChange = (e) => {
        setNewBrandingData({
            ...newBrandingData,
            logoSubtitle: {
                ...newBrandingData.logoSubtitle,
                val: e.target.value,
                customVal: e.target.value
            }
        })
    }

    const handleSubtitleRevert = () => {
        setNewBrandingData({
            ...newBrandingData,
            logoSubtitle: {
                ...newBrandingData.logoSubtitle,
                val: renderedBranding.logoSubtitle.originalVal,
                customVal: null
            }
        })
    }

    return (
        <div id="theme-branding">
            <fieldset>
                <div className="theme-checkbox-container">
                    <h4>Logo Graphic</h4>
                    <ToggleCheckbox
                        linkedTo="brandlogocheckbox"
                        defaultChecked={newBrandingData.logoImage.display}
                        onChange={handleLogoDisplay}
                    />
                </div>
                {renderedBranding.logoImage.display &&
                    <div>
                        <div className="theme-logo-picker-container">
                            { 
                                renderedBranding.logoImage.src ?
                                <img className="theme-logo-img" alt="School Logo" src={renderedBranding.logoImage.src} /> :
                                <UploadLogo className="theme-upload-img" alt="Upload Icon" />
                                
                            }
                        </div>
                        {
                            renderedBranding.logoImage.originalSrc === renderedBranding.logoImage.src &&
                            <label className="theme-uploader" tabIndex="0">
                                <span>Choose File</span>
                                <input type="file" accept="image/*"
                                    onChange={handleImageSelect}
                                />
                            </label>
                        }

                        {
                            renderedBranding.logoImage.originalSrc !== renderedBranding.logoImage.src &&
                            <button
                                className="reset-branding icon-spinner1"
                                type="button"
                                title="Clear Custom Logo"
                                onClick={handleImageRemove}
                            >
                                <div className="tb-hide">Reset Branding</div>
                            </button>
                        }
                    </div>
                }
            </fieldset>

            <fieldset>
                <div className="theme-checkbox-container">
                    <h4>Title</h4>
                    <ToggleCheckbox
                        linkedTo="brandtitlecheckbox"
                        defaultChecked={renderedBranding.logoTitle.display}
                        onChange={handleTitleDisplay}
                    />
                    
                </div>
                <input type="text" className="branding-text-input" name="brandingTitle"
                    disabled={!renderedBranding.logoTitle.display}
                    onChange={handleTitleInputChange}
                    id="brandingTitle"
                    value={renderedBranding.logoTitle.val}
                />
                <label className="hide" htmlFor="brandingTitle" />
                {
                    renderedBranding.logoTitle.originalVal !== renderedBranding.logoTitle.val &&

                    <button
                        className="reset-branding icon-spinner1"
                        type="button"
                        title="Revert Title"
                        onClick={handleTitleRevert}
                    >
                        <div className="tb-hide">Reset Branding</div>
                    </button>
                }
            </fieldset>


            {
                renderedBranding.logoSubtitle.originalVal &&
                <fieldset>
                <div className="theme-checkbox-container">
                    <h4>Subtitle</h4>
                    <ToggleCheckbox
                        linkedTo="brandsubtitlecheckbox"
                        defaultChecked={renderedBranding.logoSubtitle.display}
                        onChange={handleSubtitleDisplay}
                    />
                    
                </div>

                <input type="text" className="branding-text-input" name="brandingSubtitle"
                    disabled={!renderedBranding.logoSubtitle.display}
                    onChange={handleSubtitleInputChange}
                    value={renderedBranding.logoSubtitle.val}
                    id="brandingSubtitle"
                />
                <label className="hide" htmlFor="brandingSubtitle" />
                    {
                        renderedBranding.logoSubtitle.originalVal !== renderedBranding.logoSubtitle.val &&

                        <button
                            className="reset-branding icon-spinner1"
                            type="button"
                            title="Revert Subtitle"
                            onClick={handleSubtitleRevert}
                        >
                            <div className="tb-hide">Reset Branding</div>
                        </button>
                    }
                </fieldset>
            }
            
        </div>
    )
}

export default BrandingSection
