import React, { useContext, useState } from 'react'
import CustomButton from '../../ReusableComponents/CustomButton/CustomButton'

import { UserContext } from '../../../providers/user/user.provider'
import { PanelContext } from '../../../providers/panels/panel.provider'

import { auth } from '../../../firebase/firebase.utils'

import './UtilityNav.scss'

const UtilityNav = () => {

    const [dropActive, setDropActive] = useState(false);

    const { handleModalClass } = useContext(PanelContext);
    const { currentUser } = useContext(UserContext);

    const handleOnMouseEnter = () => {
        setDropActive(true);
    }

    const handleOnMouseLeave = () => {
        setDropActive(false);
    }


    return (
        <nav className="tb-nav-utility">
        <div className={"tb-current-user-welcome " + (currentUser.currentUser ? 'user-dropdown-availiable' : '')} onMouseEnter={handleOnMouseEnter} onMouseLeave={handleOnMouseLeave}>
            Welcome
            <div className="tb-current-user-displayname">
                {
                    currentUser.currentUser ? currentUser.currentUser.displayName : "Guest"
                }
                { currentUser.currentUser && <span className="icon-chevron-down"></span> }
                { (dropActive && currentUser.currentUser) &&
                 <div className="user-dropdown"><CustomButton className="tb-delete-account-button" onClick={()=>handleModalClass('modal-delete-user')}>Delete Account</CustomButton></div> }
            </div>
        </div>
            <ul>
                    {
                        currentUser.currentUser ?
                        <li><CustomButton onClick={() => auth.signOut()}><span>SIGN OUT</span></CustomButton></li>
                        :
                        <li><CustomButton className="tb-utility-nav-sign-in" onClick={()=>handleModalClass('modal-signin')}><span>SIGN IN</span></CustomButton></li>
                    }
                    {   currentUser.currentUser ?
                        <>
                            <li><CustomButton onClick={()=>handleModalClass('modal-projects')}><span>MY PROJECTS</span></CustomButton></li>
                            <li><CustomButton onClick={()=>handleModalClass('modal-export')} className="tb-export"><span>REVIEW & EXPORT</span></CustomButton></li>
                        </>
                        : null
                    }
            </ul>
            
        </nav>
    )
}

export default UtilityNav
