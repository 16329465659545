import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

firebase.initializeApp(config);


export const createUserProfileDocument = async (userAuth, additionalData) => {
    if (!userAuth) return;
  
    const userRef = firestore.doc(`users/${userAuth.uid}`);
  
    const snapShot = await userRef.get();

    if (!snapShot.exists) {
      const { displayName, email } = userAuth;
      const createdAt = new Date();
      try {
        await userRef.set({
          displayName,
          email,
          createdAt,
          
          ...additionalData
        });
      } catch (error) {
        console.log('error creating user', error.message);
      }
    }
    // console.log('userRef: ', userRef);
    return userRef;
};

export const addProjectsToUser = async (projects) => {
  const userAuth = firebase.auth().currentUser;
  if (!userAuth) return;
  const projectRef = firestore.collection(`users/${userAuth.uid}/projects`);
  const newProjRef = projectRef.doc('projects');
  const snapShot = await newProjRef.get();
  if(snapShot.exists){
    try {
      await newProjRef.update({
        projects: projects
      });
    } catch (error) {
      console.log('error updating projects', error.message);
    }
  }else{
    try {
      await newProjRef.set({
        projects: projects
      });
    } catch (error) {
      console.log('error creating projects', error.message);
    }
  }  
}


export const getProjectsFromDB = async () => {
  const userAuth = firebase.auth().currentUser;
  if (!userAuth) return;
  const projectRef = firestore.collection(`users/${userAuth.uid}/projects`);
  const newProjRef = projectRef.doc('projects');
  try {
    const doc = await newProjRef.get()
    const project = doc.data();
    const parseResponse = {
      ...project.projects
    }
    return parseResponse;
  } catch(error) {
    console.log("Error getting cached document:", error);
  }
}

export const deleteUser = async () => {
  return new Promise(resolve => {
    const userAuth = firebase.auth().currentUser;
    const userDB = firestore.collection('users').doc(userAuth.uid);
    
    userDB.delete().then(() => {
      console.log("Document successfully deleted!");
      resolve('user has been deleted');
      
      userAuth.delete().then(function() {
        console.log('user has been deleted');
      }).catch(function(error) {
        console.log(error + 'not deleted successfully');
      });
    }).catch((error) => {
        console.error("Error removing document: ", error);
    });

    
  })
  
}


export const auth = firebase.auth();
export const firestore = firebase.firestore();

const provider = new firebase.auth.GoogleAuthProvider();
provider.setCustomParameters({prompt: 'select_account'});
export const signInWithGoogle = () => auth.signInWithPopup(provider);

export default firebase;