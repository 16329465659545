import React, { useContext, useState } from 'react'
import { Droppable } from 'react-beautiful-dnd'

import PanelList from '../../PanelComponents/PanelList/PanelList'

import { PanelContext } from '../../../providers/panels/panel.provider'

import './PanelSection.scss'

const PanelSection = () => {

    const { renderedColumns, columnOrder } = useContext(PanelContext);
    
    const [selectedColumn, setSelectedColumn] = useState(null)

    const handleSelectColumn = (id) => {
        setSelectedColumn(id);
        console.log(id);
        document.getElementById('tbPanelListContainer').classList.add("active");
    }

    const handlePanelListBack = () => {
        document.getElementById('tbPanelListContainer').classList.remove("active");
    }

    return (
        <div className="tb-panel-section">
            <div id="tbPanelNav">
                <div className="tip-add-sections">
                    <i className="icon-light-bulb" aria-hidden="true"></i> Add sections to build your page
                </div>
                {columnOrder.map((columnId) => {
                    const column = renderedColumns[columnId];

                    if (column.id !== "canvas" && column.id !== "styleguide" && column.id !== "mobilemenu" && column.id !== "mobilemenu-2") {
                        var expandButton = <button key={`nav-${column.title}`} onClick={()=>handleSelectColumn(column.id)}>{column.title}<i className="icon-angle-right" aria-hidden="true"></i></button>;
                        
                        if (column.id === "headers") {
                            return (<div key="required-sections"><div className="sidebar-nav-tips"><h3>REQUIRED SECTIONS</h3>Select one of each</div><button key={`nav-${column.title}`} onClick={()=>handleSelectColumn(column.id)}>{column.title}<i className="icon-angle-right" aria-hidden="true"></i></button></div>);
                        }
                        if (column.id === "slideshows") {
                            return (<div key="content-sections"><div className="sidebar-nav-tips"><h3>CONTENT SECTIONS</h3></div><button key={`nav-${column.title}`} onClick={()=>handleSelectColumn(column.id)}>{column.title}<i className="icon-angle-right" aria-hidden="true"></i></button></div>);
                        }
                        return expandButton;
                    }
                    return null
                })}

                <div className="tb-version">
                    &copy; {new Date().getFullYear()} | Finalsite Theme Builder v3.0.17
                </div>
            </div>
            <div id="tbPanelListContainer">
                <div className="tb-panel-list-header" onClick={()=>handlePanelListBack()}>
                    <i className="icon-angle-left" aria-hidden="true"></i>
                    {renderedColumns[selectedColumn] ? renderedColumns[selectedColumn].title : null}
                </div>
                {renderedColumns[selectedColumn] && renderedColumns[selectedColumn].info ? <div className="tip-add-sections"><i className="icon-light-bulb"></i>{renderedColumns[selectedColumn].info}</div> : null}
            
                {
                    selectedColumn ? (
                        <Droppable
                            droppableId={renderedColumns[selectedColumn].id}
                        >
                            {(provided, snapshot) => (
                                <div
                                    className="droppable-panel-list"
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}
                                >
                                    <PanelList column={renderedColumns[selectedColumn]} />
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    ) :
                    null
                }
            </div>
        </div>
    )
}

export default PanelSection
