import React from 'react'

import './ModalCloseButton.scss'

const ModalCloseButton = ({onClick}) => {
    return (
        <button href="#" onClick={onClick} className="modal-close-button">close modal</button>
    )
}

export default ModalCloseButton
