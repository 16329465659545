import React, {useEffect} from 'react'

import './CustomButton.scss'

const CustomButton = props => {

    const {
        label,
        type,
        onClick,
        className,
        children
        
    } = props;


    return (
        <button
            className={(className ? `${className} ` : '') + 'tb-custom-button'}
            type={type}
            onClick={onClick}
        >
            {children ? children : label}
        </button>
    )
}

export default CustomButton
