import React, { useContext, useEffect, useRef } from 'react'

import ModalFonts from '../ModalFonts/ModalFonts'
import ModalSignInSignUp from '../ModalSignInSignUp/ModalSignInSignUp'
import ModalProjects from '../ModalProjects/ModalProjects'
import ModalForgotPassword from '../ModalForgotPassword/ModalForgotPassword'
import ModalExport from '../ModalExport/ModalExport'
import ModalDeleteUser from '../ModalDeleteUser/ModalDeleteUser'

import './ModalContainer.scss'

import { PanelContext } from '../../../providers/panels/panel.provider'

const ModalReturn = ({modalClass, handleModalClass}) => {
    
    if(modalClass === 'modal-fonts'){ return<ModalFonts handleModalClass={handleModalClass} /> }
    if(modalClass === 'modal-signin'){ return<ModalSignInSignUp handleModalClass={handleModalClass} /> }
    if(modalClass === 'modal-projects'){ return<ModalProjects handleModalClass={handleModalClass} /> }
    if(modalClass === 'modal-forgotpassword'){ return<ModalForgotPassword handleModalClass={handleModalClass} /> }
    if(modalClass === 'modal-export'){return<ModalExport handleModalClass={handleModalClass} />}
    if(modalClass === 'modal-delete-user'){return<ModalDeleteUser handleModalClass={handleModalClass} />}
    return false;
}

const ModalContainer = () => {

    const { modalClass, handleModalClass } = useContext(PanelContext);
    const modalRef = useRef();

    useEffect(()=>{
        if(modalClass){
            modalRef.current.focus();
        }
    },[modalClass])

    return (
        <div className={`tb-modal ${modalClass ? 'active' : 'hidden'}`}>
            {
                modalClass && <button ref={modalRef} className="tb-modal-overlay" onClick={()=>handleModalClass('')}>Close Overlay</button>
            }
            <div className={`tb-modal-inner ${modalClass}`}>
                <ModalReturn modalClass={modalClass} handleModalClass={handleModalClass} />
            </div>
        </div>
    )
}

export default ModalContainer
