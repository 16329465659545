import React, { useEffect, useContext } from 'react'

import PanelSection from '../PanelSection/PanelSection'
import StylingSection from '../StylingSection/StylingSection'

import { PanelContext } from '../../../providers/panels/panel.provider'

import './SideBarTabs.scss'

const SideBarTabs = () => {

    const { canvasView } = useContext(PanelContext);


    


    useEffect(()=>{
        // # initialize activating first tab & button
        let firstTab = document.getElementById('sideBarTabs').children[0];
        let firstSection = document.getElementById('sideBarSections').children[0];
        firstTab.classList.add("active");
        firstSection.classList.add("active");
    },[]);

    const handleActiveSection = (evt) => {
        // # Grab event target and find the index from its parent
        evt = evt || window.event
        let targetButton = evt.target || evt.srcElement;
        let navElements = [...targetButton.parentElement.children];
        let sideBarSections = [...document.getElementById('sideBarSections').children];
        
        // # index of clicked button
        let index = navElements.indexOf(targetButton);
        let selectedElement = navElements[index];
        
        // # remove active then set active
        navElements.forEach(el=>{
            el.classList.remove("active");
        });
        sideBarSections.forEach(el=>{
            el.classList.remove("active");
        });
        selectedElement.classList.add("active");
        sideBarSections[index].classList.add("active");
    }

    return (
        <div className={"tb-side-bar " + canvasView}>
            <nav id="sideBarTabs">
                <button onClick={()=>handleActiveSection(this)}><i className="icon-plus1" aria-hidden="true"></i> Add</button>
                <button onClick={()=>handleActiveSection(this)}><i className="icon-brush" aria-hidden="true"></i> Style</button>
            </nav>
            <div id="sideBarSections">
                
                    <PanelSection />
                    <StylingSection />

            </div>
        </div>
    )
}

export default React.memo(SideBarTabs)
