import {
    REMOVE_PANEL,
    DRAG_END,
    ADD_EMPTY_PROJECT,
    UPDATE_BRANDING,
    SET_CURRENT_PROJECT,
    SET_USER_PROJECTS,
    COMBINE_PROJECT,
    PARSE_PROJECT,
    ADD_ADDITIONAL_PROJECT,
    GET_ORIGINAL_HEADER_DATA
} from './panel.types'

export default (state, action) => {
    switch (action.type) {
        case REMOVE_PANEL:
            return {
                ...state,
                columns: action.payload
            }
        case DRAG_END:
            return {
                ...state,
                columns: action.payload
            }
        case UPDATE_BRANDING:
            return {
                ...state, 
                branding: action.payload
            }
        case GET_ORIGINAL_HEADER_DATA: 
            return {
                ...state,
                branding: {
                    ...state.branding,
                    logoImage: {
                        ...state.branding.logoImage,
                        src: action.payload.currentSrc,
                        originalSrc: action.payload.originalSrc
                    },
                    logoTitle: {
                        ...state.branding.logoTitle,
                        val: action.payload.currentTitle,
                        originalVal: action.payload.originalTitle
                    },
                    logoSubtitle: {
                        ...state.branding.logoSubtitle,
                        val: action.payload.currentSubtitle,
                        originalVal: action.payload.originalSubtitle
                    },
                }
            }
        case COMBINE_PROJECT: 
            return {
                ...state,
                combinedProject: action.payload
            }
        case ADD_EMPTY_PROJECT:
            return {
                ...state,
                currentProject: 'untitled-project',
                userProjects: action.payload
            }
        case SET_CURRENT_PROJECT:
            return {
                ...state,
                currentProject: action.payload
            }
        case SET_USER_PROJECTS:
            return {
                ...state,
                userProjects: action.payload
            }
        case ADD_ADDITIONAL_PROJECT:
            return {
                ...state,
                currentProject: action.payload.newCurrent,
                userProjects: action.payload.newProjects
            }
        case PARSE_PROJECT:
            return {
                ...state,
                branding: action.payload.branding,
                columns: action.payload.columns
            }
        default: {
            return state
        }
    }
}