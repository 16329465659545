import React, { useContext, useState } from 'react'

import CustomButton from '../../ReusableComponents/CustomButton/CustomButton'
import FormInput from '../../ReusableComponents/FormInput/FormInput'
import ProjectItem from './ProjectItem/ProjectItem'
import ModalCloseButton from '../../ReusableComponents/ModalCloseButton/ModalCloseButton'

import { PanelContext } from '../../../providers/panels/panel.provider'
import {UserContext} from '../../../providers/user/user.provider'
import { SET_CURRENT_PROJECT, SET_USER_PROJECTS, ADD_ADDITIONAL_PROJECT } from '../../../providers/panels/panel.types'

import './ModalProjects.scss'

const ModalProjects = ({handleModalClass}) => {

    const { initialProjectObj, userProjects, freshProjectObject, currentProject, handleParseProject, handleSwitchCurrentProject, handleSwitchToNewProject, dispatch } = useContext(PanelContext);
    const { currentUser } = useContext(UserContext);

    // console.log(currentUser.currentUser.email);
    const hasFSAccount = currentUser.currentUser.email.indexOf('@finalsite.com', currentUser.currentUser.email.length - '@finalsite.com'.length) !== -1;

    const [newProjectName, setNewProjectName] = useState('');
    const [newProjectImportName, setNewProjectImportName] = useState('');
    const [renameField, setRenameField] = useState('');
    const [renameView, toggleRenameView] = useState(false);
    const [projectToRename, setProjectToRename] = useState('')

    const addFreshProject = (e) => {
        e.preventDefault();
        // Check if their new project name already exists

        if(newProjectName in userProjects){
            alert("A project with that name already exists!");
            return false;
        }


        const newProjectObject = {
            [newProjectName]: freshProjectObject,
            ...userProjects
        }
        const newPayload = {
            newCurrent: newProjectName,
            newProjects: newProjectObject
        }

        
        dispatch({type: ADD_ADDITIONAL_PROJECT, payload: newPayload });

        handleSwitchToNewProject();

        //dispatch({type: SET_USER_PROJECTS, payload: newProjectObject });

        setNewProjectName('')
    }

    const handleChange = (e) => {
        const { value } = e.target;
        setNewProjectName(value);
    }

    const handleChangeImport = (e) => {
        const { value } = e.target;
        setNewProjectImportName(value);
    }
    const handleRenameChange = (e) => {
        const { value } = e.target;
        setRenameField(value);
    }

    const handleRenameProject = (e) => {
        e.preventDefault();
        // Check if their new project name already exists
        if(renameField in userProjects){
            alert("A project with that name already exists!");
            return false;
        }

        let newProject = Object.assign({}, userProjects[projectToRename]);
        let newProjectObject = Object.assign({}, userProjects);

        delete newProjectObject[projectToRename];

        
        newProjectObject = {
            ...newProjectObject,
            [renameField]: newProject
        }

        const newPayload = {
            newCurrent: renameField,
            newProjects: newProjectObject
        }

        
        dispatch({type: ADD_ADDITIONAL_PROJECT, payload: newPayload });
        
        toggleRenameView(false);
    }

    const handleDeleteProject = (projectName) => {
        const newUserProjects = Object.assign({}, userProjects);
        delete newUserProjects[projectName];
        dispatch({type: SET_USER_PROJECTS, payload: newUserProjects})
        
        if(projectName === currentProject && Object.keys(userProjects).length > 0){
            const newCurrentProject = Object.keys(newUserProjects)[0]
            handleLoadProject(newCurrentProject)
            //return false
        }
    }

    const handleLoadProject = (projectName) => {
        dispatch({type: SET_CURRENT_PROJECT, payload: projectName });
        handleSwitchCurrentProject(projectName);
    }

    const importProject = (e) => {
        e.preventDefault();        

        // get rid of "smart" quotes
        let strippedProjectImportName = newProjectImportName.replace(/[\u2018\u2019]/g, "'").replace(/[\u201C\u201D]/g, '"');;

        let parseImport = JSON.parse(strippedProjectImportName);

        const importProjectObject = {canvas: {...parseImport[1]}, branding: { ...parseImport[2] }};

        
        if(parseImport[0] in userProjects){
            alert("A project with that name already exists!");
            return false;
        }


        const newProjectObject = {
            [parseImport[0]]: importProjectObject,
            ...userProjects
        }
        const newPayload = {
            newCurrent: parseImport[0],
            newProjects: newProjectObject
        }

        // console.log(newProjectObject[newProjectImportName]);
        // console.log('new payload', newPayload.newCurrent);

        
        dispatch({type: ADD_ADDITIONAL_PROJECT, payload: newPayload });
        // dispatch({type: SET_CURRENT_PROJECT, payload: newPayload });

        // handleSwitchToNewProject();
        const handleSwitchImportProject = (currentProject) => {
            console.log(currentProject);
            const newProjectData = {
              ...initialProjectObj,
              branding: {...parseImport[2]},
              columns: {
                ...initialProjectObj.columns,
                canvas: {
                  ...parseImport[1]
                }
              }
            }

            handleParseProject(newProjectData);
            // console.log(newProjectData);
      
      
        }

        handleSwitchImportProject(newPayload.newCurrent);
        

        // dispatch({type: SET_USER_PROJECTS, payload: newProjectObject });

        setNewProjectImportName('');
    }
    

    return (
        <>  
            <ModalCloseButton onClick={()=>handleModalClass('')}>Close</ModalCloseButton>
            <div className="modal-title">Projects</div>
            <div className="modal-subtitle">
                { !hasFSAccount ?
                    'You can have up to three projects saved so you can try out new ideas and compare them easily.' :
                    'With a finalsite.com email, you are able to save an unlimited number of projects.'
                }
                
                {
                    Object.keys(userProjects).length > 1 ?
                    <span> Click on a project to load.</span> :
                    null
                }
            </div>
            
            <div className="modal-project-list">
                {
                    Object.keys(userProjects).length > 0 ?
                    Object.keys(userProjects).map((project, index) => (
                        <ProjectItem
                            key={project}
                            name={project}
                            index={index}
                            handleDeleteProject={handleDeleteProject}
                            toggleRenameView={toggleRenameView} 
                            setProjectToRename={setProjectToRename}
                            handleLoadProject={handleLoadProject}
                        />
                    )) :
                    null
                }
            </div>
            {
                (Object.keys(userProjects).length < 3 && !hasFSAccount) || hasFSAccount ? 
                <>
                    <div className="tb-line-divider"><span>Add another project</span></div>
                    <form className='add-project-form' onSubmit={addFreshProject}>
                        <FormInput
                            type='text'
                            name='projectName'
                            value={newProjectName}
                            onChange={handleChange}
                            label='Project Name'
                            required
                        />
                        <CustomButton className="tb-modal-button" type="submit">Add</CustomButton>
                    </form>
                </>
                : null
            }
            {
                hasFSAccount ? 
                <>
                    <div className="tb-line-divider"><span>Import project</span></div>
                    <form className='add-project-form' onSubmit={importProject}>
                        <FormInput
                            type='text'
                            name='projectName'
                            value={newProjectImportName}
                            onChange={handleChangeImport}
                            label='Paste Code Here'
                            required
                        />
                        <CustomButton className="tb-modal-button" type="submit">Import</CustomButton>
                    </form>
                </>
                : null
            }
            {
                renameView ?
                <>
                    <CustomButton className="tb-modal-button rename-back-button" onClick={()=>toggleRenameView(false)}><i className="icon-angle-left" aria-hidden="true"></i> Back to Projects</CustomButton>
                    <form className='rename-form' onSubmit={handleRenameProject}>
                        <div className="modal-title">Renaming project:</div>
                        <div className="modal-subtitle">{projectToRename}</div>
                        <FormInput
                            type='text'
                            name='renameField'
                            value={renameField}
                            onChange={handleRenameChange}
                            label='Rename Project'
                            required
                        />
                        <CustomButton className="tb-modal-button rename-project-submit" type="submit">Submit</CustomButton>
                    </form>
                </>
                : null
            }
        </>
    )
}

export default ModalProjects
