import React, { useContext, useEffect } from 'react'

import ToggleCheckbox from '../../ReusableComponents/ToggleCheckbox/ToggleCheckbox'

import { PanelContext } from '../../../providers/panels/panel.provider'

import './DistrictSection.scss'
import { UPDATE_BRANDING } from '../../../providers/panels/panel.types'

// import $ from 'jquery'


const DistrictSection = () => {

    const { renderedBranding, dispatch } = useContext(PanelContext);

    const handleDistrictDisplay = (e) => {
        const newBranding = {
            ...renderedBranding,
            district: e.target.checked
        }
        dispatch({type: UPDATE_BRANDING, payload: newBranding});
        if (renderedBranding.district) {
            // $('body').addClass('district-active');
            document.body.classList.add('district-active')
        } else {
            // $('body').removeClass('district-active');
            document.body.classList.remove('district-active')
        }
    }
    

    useEffect(() => {

        if (renderedBranding.district) {
            document.body.classList.add('district-active');
            // if ($('.nav-district').length) {
            //     const target = $('.nav-district').offset().top;
                // console.log(target)

                // $('html,body').animate({
                //     scrollTop: target
                // }, 300);
            // }
        } else {
            document.body.classList.remove('district-active');
        }
    }, [renderedBranding.district]);

    // useEffect(() => {
    //     if (renderedBranding.district) {
    //         // $('body').addClass('district-active');
    //         document.body.classList.add('district-active')
    //     } else {
    //         // $('body').removeClass('district-active');
    //         document.body.classList.remove('district-active')
    //     }
    // }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className="tb-theme-district">
            <div className="livedemo-description">Toggle to enable the district navigation bar</div>
            <div className="theme-checkbox-container">
                <h4>District Navigation</h4>
                <ToggleCheckbox
                    linkedTo="districtCheckbox"
                    defaultChecked={renderedBranding.district}
                    onChange={handleDistrictDisplay}
                />
            </div>
        </div>
    )
}

export default DistrictSection
