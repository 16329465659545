import React, { createContext, useState, useContext, useEffect, useReducer, useRef } from 'react';

import { default as panels } from './initialdata/paneldata.js'
import { initialProjectData, columnOrderdata } from './initialdata/initial.project.data.js'
import useDebounceHook from '../../components/ReusableComponents/useDebounceHook/useDebounceHook'

import panelReducer from './panel.reducer'

// types for the panel reducer
import {
  REMOVE_PANEL,
  DRAG_END,
  ADD_EMPTY_PROJECT,
  COMBINE_PROJECT,
  PARSE_PROJECT,
  SET_CURRENT_PROJECT,
  SET_USER_PROJECTS,
  GET_ORIGINAL_HEADER_DATA
} from './panel.types'

// utility functions related to manipulating the panel data
import {
  parseDragEnd,
  loadJS,
  postIncludes,
  onRemovePanel,
  checkHeight
} from './panel.utils'

// utility functions specific to branding data
import {
  pushBrandingToCanvas,
  getOriginalHeaderData
} from './branding.utils'

// utility functions specific to the user projects for database storage
import {
  pushProjectsToDB,
  loadProjects,
  parseLoadedData
} from './projects.utils'

// need user data for some functions
import { UserContext } from '../user/user.provider'

export const PanelContext = createContext();

const PanelProvider = ({ children }) => {

    // assigning the initial data to variables
    const panelData = Object.assign({}, panels);
    const columnOrder = [...columnOrderdata];
    const initialProjectObj = Object.assign({}, initialProjectData);
    
    // setting up the initial state for the main reducer
    const initialPanelState = {
      columns: initialProjectObj.columns,
      branding: initialProjectObj.branding,
      disableDrag: false,
      combinedProject: {canvas: {...initialProjectObj.columns.canvas}, branding: {...initialProjectObj.branding} },
      userProjects: {},
      currentProject: ''
    }

    // creating the main reducer
    const [state, dispatch] = useReducer(panelReducer, initialPanelState);

    // grabbing current user from the user provider
    const { currentUser } = useContext(UserContext);

    // establishing some state to keep track of
    const [disableDrag, setDisableDrag] = useState(false);
    const [canvasView, setCanvasView] = useState('homepage');
    const [modalClass, setModalClass] = useState('');
    
    const handleRemovePanel = panel => dispatch({type: REMOVE_PANEL, payload: onRemovePanel(panel, state.columns)});
    const handleDragEnd = result => dispatch({type: DRAG_END, payload: parseDragEnd(result, state.columns)});

    const handleCanvasView = (view) => setCanvasView(view);
    const handleBrandingToCanvas = branding => pushBrandingToCanvas(branding);
    const handleLoadJS = async (panel) => {
        try {
          await loadJS(panel);
          return true
        } catch (error) {
          console.log(error);
        }
      }

    
    
    //const [combinedProjectObject, setCombinedProjectObject] = useState({canvas: {...renderedColumns.canvas}, branding: { ...renderedBranding }});
    const runLocalApiChain = (combinedProject) => postIncludes(combinedProject);

    
    // function passes the name of the modal class to the state modalClass
    const handleModalClass = (className) => setModalClass(className);
      
    
    // saves their current project. is called when any of their project data changes
    const saveCurrentProject = (projectObject) => {
      if(currentUser.currentUser){
        dispatch({type: SET_USER_PROJECTS, payload: pushProjectsToDB(projectObject, state.currentProject, state.userProjects)});
      }
    }
    
    
    // parse project is my way of taking project data, and running it through themebuilder
    // to overwrite all the initial project data to whatever data they had for their project.
    const handleParseProject = (newProjectData) => {
      // i parse the columns and their branding, then compbine them
      const parsedColumns = parseLoadedData(panelData,newProjectData.columns);
      const parsedBranding = newProjectData.branding;
      const parsedProject = {
        columns: parsedColumns,
        branding: parsedBranding
      }
      // console.log('parsed project: ', parsedProject);
      // then i dispatch their project into the reducer (which overwrites the main data and will display it)
      dispatch({type: PARSE_PROJECT, payload: parsedProject});
    }
    
    // I create a fresh project based on all the initial defaults. useful for if we ever want to 'clear everything' the user has done
    // and also useful to create the first project when a user is created
    const freshProjectObject = {canvas: {...initialProjectObj.columns.canvas}, branding: { ...initialProjectObj.branding }}
    const addFirstProject = () => dispatch({type: ADD_EMPTY_PROJECT, payload: {'untitled-project': freshProjectObject}})

    // if there is no user projects on their account in the database, add first project (right above, with the fresh project object)
    useEffect(() => {
      // console.log('use effect userProjects', userProjects, !userProjects)
      if(!state.userProjects || Object.keys(state.userProjects).length === 0){
        //console.log('use effect for userprojects === 0')
        addFirstProject();
      }
    },[state.userProjects]); // eslint-disable-line react-hooks/exhaustive-deps
    
    
    
    //init freshProjectObject to be used for varius things
    const handleLoadProjects = async () => {
      try {
        const loadedProjects = await loadProjects();
        dispatch({type: SET_CURRENT_PROJECT, payload: loadedProjects.currentProject});
        
        // remove saved currentProject, cant use it here anymore.
        var parsedLoadedProjects = Object.assign({}, loadedProjects);
        delete parsedLoadedProjects['currentProject'];
        dispatch({type: SET_USER_PROJECTS, payload: parsedLoadedProjects});
        
        // console.log('loaded projects', parsedLoadedProjects);
        const newProjectData = {
          ...initialProjectObj,
          columns: {
            ...initialProjectObj.columns,
            canvas: {
              ...loadedProjects[loadedProjects.currentProject].canvas
            }
          },
          branding: {...loadedProjects[loadedProjects.currentProject].branding}
        }
        
        handleParseProject(newProjectData)
      } catch (error) {
        console.log(error);
      }
    };
    
    //!! DOESNT WORK, NOT IN SYNC when current project name changes, in all cases, load that project to canvas
    // useEffect(()=>{
      //   if(state.currentProject !== ''){
        //     console.log('CURRENTPROJECT : ' + state.currentProject);
        
        //     handleSwitchCurrentProject(state.currentProject);
        //   }
        // },[state.currentProject]);
    
    // Since we are keeping track of the name of their projects, we can use that to switch projects
    const handleSwitchCurrentProject = (currentProject) => {
      console.log(currentProject);
      const newProjectData = {
        ...initialProjectObj,
        branding: {...state.userProjects[currentProject].branding},
        columns: {
          ...initialProjectObj.columns,
          canvas: {
            ...state.userProjects[currentProject].canvas
          }
        }
      }
      handleParseProject(newProjectData);
      // console.log(newProjectData);


    }
      
    // clears everything to the initial data
    const handleSwitchToNewProject = () => {
      const newProjectData = {
        ...initialProjectObj
      }
      handleParseProject(newProjectData)
    }
    
    
    // disable ability to drag panels if not on homepage canvas view
    useEffect(()=>{
      if(canvasView === "homepage"){
        setDisableDrag(false)
        document.body.classList.add("home");
      }else{
        setDisableDrag(true)
        document.body.classList.remove("home");
      }
      checkHeight();
    },[canvasView]);
    
    //Combine branding and canvas to 1 object after either the columns change or the branding changes
    // I'm combining these into one object to store as a user project on the database
    // the useRef below was an attempt to debounce or stop the function below from firing multiple times
    // Its essentiall ignoring the first time it fires.
    const firstAppProjectData = useRef(true);
    useEffect(()=>{

      // REFACTOR maybe if appProjectData.canvas !== currentproject.canvas ( might save 1 db push )
      if(firstAppProjectData.current){
        firstAppProjectData.current = false;
        return;
      }

      dispatch({type: COMBINE_PROJECT, payload: {canvas: {...state.columns.canvas}, branding: { ...state.branding }}})
      
    },[state.columns, state.branding])
    

    // Since the app pushes the users projects to the database after every change they make
    // I couldn't think of a good way to stop it from pushing when the app loads their projects
    // this was an attempt to stop react from pushing to the database twice
    // could be rethought and recoded
    const firstSaveToDb = useRef(true);
    const debounceSave = useDebounceHook(state.combinedProject, 500);
    useEffect(()=>{
      if(firstSaveToDb.current){
        firstSaveToDb.current = false;
        return;
      }

      runLocalApiChain({panels: panels, ...state.combinedProject});
      saveCurrentProject(state.combinedProject);
      pushBrandingToCanvas(state.combinedProject.branding);

    },[debounceSave]); // eslint-disable-line react-hooks/exhaustive-deps
    
    
    useEffect(()=>{
      // this is saying "if the current user exists (session logged in)" make sure to close all the modals
      // useful for when the signup or signin modal is open, when the user authenticates i set the modal to '' nothing and it closes
      // plus i load their projects from the database
      if(currentUser.currentUser) {
        handleLoadProjects();
        setModalClass('');
      }

      // I was toying with the idea of when the current user is logged in, and they have no projects, it would open the project modal
      // this turned out to not be a comfortable flow for the user, but could still be applied if we want
      if(!currentUser.projects) {
        //setModalClass('modal-projects');
      }
    },[currentUser]); // eslint-disable-line react-hooks/exhaustive-deps

    const windowThemeHeaderData = () => {
      dispatch({type: GET_ORIGINAL_HEADER_DATA, payload: getOriginalHeaderData(state.branding)})
    }

    return (
        <PanelContext.Provider
          value={{
            panelData,
            columnOrder,
            initialProjectObj,
            initialProjectData,
            disableDrag,
            addFirstProject,
            handleParseProject,
            handleRemovePanel,
            handleDragEnd,
            canvasView,
            handleCanvasView,
            handleBrandingToCanvas,
            handleLoadJS,
            modalClass,
            handleModalClass,
            userProjects: state.userProjects,
            combinedProjectObject: state.combinedProject,
            currentProject: state.currentProject,
            freshProjectObject,
            renderedBranding: state.branding,
            renderedColumns: state.columns,
            handleSwitchCurrentProject,
            handleSwitchToNewProject,
            windowThemeHeaderData,
            dispatch
          }}
        >
          {children}
        </PanelContext.Provider>
    );
}

export default PanelProvider;