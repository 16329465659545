import React, {useContext} from 'react'

import FontsPicker from './FontPicker'

import { PanelContext } from '../../../providers/panels/panel.provider'

const FontSection = () => {
    const { renderedBranding, handleModalClass } = useContext(PanelContext);
    const fonts = renderedBranding.fonts;

    const handleCustomFontModal = e => {
        e.preventDefault();
        handleModalClass('modal-fonts');
    }

    return (
        <div id="theme-fonts">
            <div className="font-section-instructions">
                <p>Adjust the fonts selections to see how different combinations affect the design.</p>
                <p>You'll be able to define specific fonts to use, not exclusive to those found here, during the implementation process.</p>
            </div>

            {fonts.map((item, index) => {
                return (
                    <FontsPicker
                        value={item.value}
                        label={item.label}
                        options={item.options}
                        key={item.label}
                        index={index}
                    />
                );
            })}

            <div className="custom-learn-more">
                <a href="/"
                    className="livedemo-link"
                    onClick={handleCustomFontModal}
                    aria-label="Click here to learn how to add a custom font from Google Fonts"
                >
                    Click Here
                </a> to learn how to add a custom font from Google Fonts.
            </div>
        </div>
    );
}

export default FontSection
